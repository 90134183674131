import React, { useEffect, useState } from "react";
import Hemburger from "../../assets/images/menuicon.svg";
import "./style.css";
import { Link } from "react-router-dom";
import close from "../../assets/images/close.png";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import RightArrow from "../../assets/images/rightarrow.svg";

function NavBar() {
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [valuesclicked, setvalclicked] = useState("");
  const [projectclicked, setprojectclicked] = useState("");
  const [processclicked, setprocessclicked] = useState("");
  const [teamclicked, setteamclicked] = useState("");
  const [careerclicked, setcareerclicked] = useState("");
  const [serviceclicked, setserviceclicked] = useState("");

  function setColor(name) {
    if (name == "val") {
      setvalclicked("clicked");
      setprocessclicked("");
      setteamclicked("");
      setcareerclicked("");
      setprojectclicked("");
      setserviceclicked("");
    } else if (name == "process") {
      setprocessclicked("clicked");
      setteamclicked("");
      setcareerclicked("");
      setprojectclicked("");
      setvalclicked("");
      setserviceclicked("");
    } else if (name == "proj") {
      setprojectclicked("clicked");
      setprocessclicked("");
      setteamclicked("");
      setcareerclicked("");
      setvalclicked("");
      setserviceclicked("");
    } else if (name == "team") {
      setteamclicked("clicked");
      setprocessclicked("");
      setcareerclicked("");
      setprojectclicked("");
      setvalclicked("");
      setserviceclicked("");
    } else if (name == "career") {
      setcareerclicked("clicked");
      setprocessclicked("");
      setteamclicked("");
      setprojectclicked("");
      setvalclicked("");
      setserviceclicked("");
    } else if (name == "service") {
      setserviceclicked("clicked");
      setprocessclicked("");
      setteamclicked("");
      setprojectclicked("");
      setvalclicked("");
      setcareerclicked("");
    }
  }

  useEffect(() => {
    // adding the event when scroll change Logo
    window.addEventListener("scroll", () => {
      var ele = document.getElementsByClassName("header stickyHeader");
      if (window.scrollY >= 90) {
        ele[0].classList.add("bgcolor");
      } else {
        ele[0].classList.remove("bgcolor");
      }
    });
  }, []);

  // const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  //   <Link
  //     ref={ref}
  //     onClick={e => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //   >
  //     {/* Render custom icon here */}
  //     <div className="d-flex align-items-center" ref={ref}
  //     onClick={e => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}>
  //     <div>
  //     {children}
  //     </div>
  //     <div className="ms-2">
  //     <img src={RightArrow}/>
  //     </div>
  //     </div>
  //    </Link>
  // ));

  return (
    <div className="header_section_container">
      <header className="header stickyHeader">
        <div className="container">
          <div className="row justify-content-between align-items-center py-0">
            <div className="heading-box g-0 d-flex align-items-center col-lg-2 col-md-6 col-sm-6 col-6">
              <Link to="/">
                <img
                  src={require("../../images/sample_logo.png")}
                  alt="logo"
                  className="h-[4.5rem] "
                />
              </Link>
            </div>
            <div className="d-lg-flex d-md-none d-sm-none d-none justify-content-between col-lg-8">
              <Link to="/ecommercepage">
                <h2
                  style={{ fontFamily: "tabloid" }}
                  className="header-heading-link"
                >
                  ECOMMERCE
                </h2>
              </Link>
              <Link to="/marketing">
                <h2
                  style={{ fontFamily: "tabloid" }}
                  className="header-heading-link"
                >
                  MARKETING
                </h2>
              </Link>
              <Link to="/sahil-mishra">
                <h2
                  style={{ fontFamily: "tabloid" }}
                  className="header-heading-link"
                >
                  Sahil Mishra
                </h2>
              </Link>
            </div>
            <div className="user-icon-box col-lg-2 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
              <Button
                variant="primary"
                onClick={handleShow}
                className="me-2 hemburger"
              >
                <img src={Hemburger} alt="menu_icon" />
              </Button>

              <Offcanvas
                show={show}
                onHide={handleClose}
                className="side-offcanvas"
              >
                <div className="container">
                  <Offcanvas.Header className="px-0" closeButton>
                    <Offcanvas.Title>
                      <div className="d-flex align-items-center">
                        <div className="logo-container me-3"></div>
                        <h3 className="header-heading">Logo </h3>
                      </div>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className="offcanvas-body p-0">
                    <div className="mt-lg-5 mt-md-5 mt-sm-0 mt-0 ms-lg-5 ms-md-5 ms-sm-2 ms-2 ps-lg-5 ps-md-5 ps-sm-0 ps-0">
                      <ul style={{ fontFamily: "tabloid" }}>
                        <li
                          style={{ fontFamily: "tabloid" }}
                          className="menu mb-5"
                        >
                          Menu
                        </li>
                        <li className="list-item"></li>
                        <Link
                          to="/"
                          state={{ to: "Projects" }}
                          onClick={() => {
                            handleClose();
                            setColor("proj");
                          }}
                        >
                          <li className={`list-item ${projectclicked}`}>
                            Projects
                          </li>
                        </Link>
                        <Link
                          onClick={() => {
                            setColor("service");
                          }}
                        >
                          <li className={`list-item ${serviceclicked}`}>
                            <Dropdown drop="end">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                className={`${serviceclicked}`}
                              >
                                Services
                                <img
                                  className="right_arrow"
                                  src={RightArrow}
                                  alt="rightarrow"
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href="#/action-1"
                                  className="dropdown_list"
                                >
                                  <Link
                                    to="/ecommercepage"
                                    onClick={() => {
                                      handleClose();
                                    }}
                                  >
                                    Ecommerce
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#/action-2"
                                  className="dropdown_list"
                                >
                                  {" "}
                                  <Link
                                    to="/marketing"
                                    onClick={() => {
                                      handleClose();
                                    }}
                                  >
                                    Marketing
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#/action-3"
                                  className="dropdown_list"
                                >
                                  <Link
                                    to="/sahil-mishra"
                                    onClick={() => {
                                      handleClose();
                                    }}
                                  >
                                    Sahil Mishra
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </Link>
                        <Link
                          to="/"
                          state={{ to: "Values" }}
                          replace
                          onClick={() => {
                            handleClose();
                            setColor("val");
                          }}
                        >
                          <li className={`list-item ${valuesclicked}`}>
                            Values
                          </li>
                        </Link>
                        <Link
                          to="/"
                          state={{ to: "Process" }}
                          replace
                          onClick={() => {
                            handleClose();
                            setColor("process");
                          }}
                        >
                          <li className={`list-item ${processclicked}`}>
                            Process
                          </li>
                        </Link>
                        <Link
                          onClick={() => {
                            handleClose();
                            setColor("team");
                          }}
                        >
                          <li className={`list-item ${teamclicked}`}>Team</li>
                        </Link>
                        <Link
                          to="/career-page"
                          onClick={() => {
                            handleClose();
                            setColor("career");
                          }}
                        >
                          <li className={`list-item ${careerclicked}`}>
                            Career
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </Offcanvas.Body>
                  <div className="social_icon row mt-4 align-items-center">
                    <div className="social-icon-container col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-start ">
                      <span className="social_icon_container me-4">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </span>
                      <span className="social_icon_container">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className="col-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                      <Link
                        to="/"
                        state={{ to: "Footer" }}
                        onClick={handleClose}
                        className="lets-grow-link"
                      >
                        {" "}
                        <b style={{ fontFamily: "tabloid" }}>Let’s grow</b>
                      </Link>
                    </div>
                  </div>
                </div>
              </Offcanvas>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
export default NavBar;
