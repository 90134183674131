import React, { useEffect, useState } from "react";
import Navbar from "../components/navBar/index";
// import Slider from '../components/Slider';

const HamburgerMenuPage = () => {
  useEffect(() => {
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth',
    // });
  }, []);

  const product1 = [
    {
      img: "prod1",
      title: "Discoball",
      brand: "Branding",
      content: "Lorem ipsum dolor sit",
    },
    {
      img: "prod2",
      title: "App your day",
      brand: "Branding",
      content: "Lorem ipsum dolor sit",
    },
    {
      img: "prod3",
      title: "Normform",
      brand: "Project",
      content: "Lorem ipsum dolor sit",
    },
  ];

  const product2 = [
    {
      img: "prod4",
      title: "Perfect",
      brand: "Creative",
      content: "Lorem ipsum dolor sit",
    },
    {
      img: "prod5",
      title: "Blue App",
      brand: "Branding",
      content: "Lorem ipsum dolor sit",
    },
    {
      img: "prod6",
      title: "Blue App",
      brand: "Creative",
      content: "Lorem ipsum dolor sit",
    },
  ];

  const product3 = [
    {
      img: "prod7",
      title: "Green room",
      brand: "Project",
      content: "Lorem ipsum dolor sit",
    },
    {
      img: "prod8",
      title: "City vibe",
      brand: "Branding",
      content: "Lorem ipsum dolor sit",
    },
    {
      img: "prod9",
      title: "Food Expert",
      brand: "Branding",
      content: "Lorem ipsum dolor sit",
    },
  ];

  const data = [
    {
      title: "1. Lorem ipsum",
      data1:
        "Lorem ipsum dolor sit amet consectetur adipiscing elit. Aliquam fringilla nisi euismod, dapibus.",
      data2:
        "Eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusa et justo duo dolores et ea rebum.",
    },
    {
      title: "1. Lorem ipsum",
      data1:
        "Ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      data2:
        "Proin congue nulla in metus egestas, at fermentum enim aliquam. Sed bibendum tincidunt leo, sit amet egestas magna.",
    },
    {
      title: "1. Lorem ipsum",
      data1:
        "Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      data2:
        "Integer quis lacus ac enim interdum congue. Fusce mauris dui, laoreet eget ligula eget, sagittis suscipit orci.",
    },
    {
      title: "1. Lorem ipsum",
      data1:
        "Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      data2:
        "Integer quis lacus ac enim interdum congue. Fusce mauris dui, laoreet eget ligula eget, sagittis suscipit orci.",
    },
    {
      title: "1. Lorem ipsum",
      data1:
        "Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
      data2:
        "Integer quis lacus ac enim interdum congue. Fusce mauris dui, laoreet eget ligula eget, sagittis suscipit orci.",
    },
  ];

  const [ob, setOb] = useState({
    Lorem1: true,
    Lorem2: false,
    Lorem3: false,
    Lorem4: false,
    Lorem5: false,
  });

  const handleClick = (key, val) => {
    setOb((prev) => {
      return {
        ...prev,
        [key]: !val,
      };
    });
  };

  return (
    <>
      <div className="bg-marketing w-full">
        <Navbar />
        <div
          id="about"
          className="px-10 lg:px-24 pt-28 sm:pt-20 md:flex items-baseline md:justify-center"
        >
          <div className="text-6xl lg:text-9xl font-tabloid font-bold text-black1 uppercase w-fit lg:w-max">
            ABOUT Amkamtech.
          </div>
          <div className="">
            <img
              src={require("../images/Menu/menu1.png")}
              alt=""
              className="lg:ml-8 w-20 sm:w-fit pb-2 sm:pb-0"
            />
          </div>
        </div>
      </div>
      <div className="p0">
        <img
          src={require("../images/Menu/menu2.png")}
          alt=""
          className="bg-marketing w-full"
        />
      </div>
      <div className="bg-marketing px-2 md:px-10 lg:px-24 pt-8 sm:pt-20 pb-8 sm:pb-28 text-title font-eb text-sm sm:text-xl leading-relaxed tracking-wider">
        <div className="text-center sm:text-justify w-[90%] mx-auto">
          Lorem ipsum dolor sit amet consectetur. Turpis mauris nisl feugiat
          vel. Nulla sapien tincidunt duis risus commodo nulla enim nam. Duis
          mauris amet in consequat malesuada commodo volutpat facilisi. Eget
          eget duis nisl aenean vitae mollis. Scelerisque tristique vel
          elementum pharetra pharetra. Semper eget pulvinar neque malesuada
          natoque facilisis rhoncus. Lectus sed suspendisse metus tellus
          volutpat. Ut interdum molestie odio convallis quis pulvinar. Diam
          magna ac varius ac in auctor semper fermentum euismod. Sagittis ornare
          gravida quis felis diam neque sed nec.
        </div>
        {/* <div className="text-center ">
        </div> */}
      </div>

      <div id="project" className="pro">
        <div className="text-4xl md:text-9xl font-tabloid font-bold text-black1 uppercase tracking-tighter">
          Projects
        </div>
        <div className="mt-2 sm:mt-10 w-full bg-black1 h-[0.1rem]"></div>

        <div className="mt-4 sm:mt-5 flex text-sm sm:text-base">
          <div className="w-fit group cursor-pointer">
            <div className="w-[90%] h-[0.2rem] group-hover:bg-white bg-black1 mb-[0.05rem] sm:-mb-1"></div>
            <div className="font-roboto uppercase">All</div>
          </div>
          <div className="ml-5 sm:ml-10 w-fit group cursor-pointer">
            <div className="w-[75%] h-[0.2rem] group-hover:bg-black1 mb-[0.05rem] sm:-mb-1"></div>
            <div className="font-roboto uppercase">Ecommerce</div>
          </div>
          <div className="ml-5 sm:ml-10 w-fit group cursor-pointer">
            <div className="w-[75%] h-[0.2rem] group-hover:bg-black1 mb-[0.05rem] sm:-mb-1"></div>
            <div className="font-roboto uppercase">Marketing</div>
          </div>
        </div>

        <div className="px-4 sm:px-0 mt-14 sm:mt-20 flex flex-col sm:flex-row items-center sm:justify-between w-full">
          {product1.map((item) => {
            return (
              <>
                <div key={item.title} className="mt-10 sm:mt-0">
                  <img
                    src={require(`../images/Menu/${item.img}.png`)}
                    alt=""
                    className="w-fit"
                  />
                  <div className="w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.25rem] group-hover: bg-black1 -mb-1"></div>
                    <div className="font-tabloid font-extrabold tracking-tighter uppercase">
                      {item.title}
                    </div>
                  </div>
                  <div className="mt-1 sm:mt-2 mb-1 sm:mb-3 w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.2rem] group-hover: bg-black1 -mb-[0.1rem] sm:-mb-[0.16rem]"></div>
                    <div className="font-tabloid font-bold sm:font-extrabold -tracking-[0.08rem]  uppercase text-xs pr-2">
                      {item.brand}
                    </div>
                  </div>
                  <div className="font-eb font-light text-sm sm:text-base">
                    {item.content}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="px-4 sm:px-0 sm:mt-20 flex flex-col sm:flex-row items-center sm:justify-between w-full">
          {product2.map((item) => {
            return (
              <>
                <div key={item.title} className="mt-10 sm:mt-0">
                  <img
                    src={require(`../images/Menu/${item.img}.png`)}
                    alt=""
                    className="w-fit"
                  />
                  <div className="w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.25rem] group-hover: bg-black1 -mb-1"></div>
                    <div className="font-tabloid font-extrabold tracking-tighter uppercase">
                      {item.title}
                    </div>
                  </div>
                  <div className="mt-1 sm:mt-2 mb-1 sm:mb-3 w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.2rem] group-hover: bg-black1 -mb-[0.1rem] sm:-mb-[0.16rem]"></div>
                    <div className="font-tabloid font-bold sm:font-extrabold -tracking-[0.08rem]  uppercase text-xs pr-2">
                      {item.brand}
                    </div>
                  </div>
                  <div className="font-eb font-light text-sm sm:text-base">
                    {item.content}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="px-4 sm:px-0 sm:mt-20 flex flex-col sm:flex-row items-center sm:justify-between w-full">
          {product3.map((item) => {
            return (
              <>
                <div key={item.title} className="mt-10 sm:mt-0">
                  <img
                    src={require(`../images/Menu/${item.img}.png`)}
                    alt=""
                    className="w-fit"
                  />
                  <div className="w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.25rem] group-hover: bg-black1 -mb-1"></div>
                    <div className="font-tabloid font-extrabold tracking-tighter uppercase">
                      {item.title}
                    </div>
                  </div>
                  <div className="mt-1 sm:mt-2 mb-1 sm:mb-3 w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.2rem] group-hover: bg-black1 -mb-[0.1rem] sm:-mb-[0.16rem]"></div>
                    <div className="font-tabloid font-bold sm:font-extrabold -tracking-[0.08rem]  uppercase text-xs pr-2">
                      {item.brand}
                    </div>
                  </div>
                  <div className="font-eb font-light text-sm sm:text-base">
                    {item.content}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        {/* 
        <div className="mt-14 flex justify-between w-full">
          {product2.map((item) => {
            return (
              <>
                <div key={item.title} className="">
                  <img
                    src={require(`../images/Menu/${item.img}.png`)}
                    alt=""
                    className="w-fit"
                  />
                  <div className="w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.25rem] group-hover: bg-black1 -mb-1"></div>
                    <div className="font-tabloid font-extrabold tracking-tighter uppercase">
                      {item.title}
                    </div>
                  </div>
                  <div className="mt-2 mb-3 w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.2rem] group-hover: bg-black1 -mb-[0.16rem]"></div>
                    <div className="font-tabloid font-extrabold -tracking-[0.08rem]  uppercase text-xs pr-2">
                      {item.brand}
                    </div>
                  </div>
                  <div className="font-eb font-light">{item.content}</div>
                </div>
              </>
            );
          })}
        </div>

        <div className="mt-14 flex justify-between w-full">
          {product3.map((item) => {
            return (
              <>
                <div key={item.title} className="">
                  <img
                    src={require(`../images/Menu/${item.img}.png`)}
                    alt=""
                    className="w-fit"
                  />
                  <div className="w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.25rem] group-hover: bg-black1 -mb-1"></div>
                    <div className="font-tabloid font-extrabold tracking-tighter uppercase">
                      {item.title}
                    </div>
                  </div>
                  <div className="mt-2 mb-3 w-fit group cursor-pointer text-black">
                    <div className="w-[100%] h-[0.2rem] group-hover: bg-black1 -mb-[0.16rem]"></div>
                    <div className="font-tabloid font-extrabold -tracking-[0.08rem]  uppercase text-xs pr-2">
                      {item.brand}
                    </div>
                  </div>
                  <div className="font-eb font-light">{item.content}</div>
                </div>
              </>
            );
          })}
        </div> */}

        <div className="mt-14 sm:mt-20 w-max mx-auto flex items-center cursor-pointer group">
          <div className="font- text-sm text-black1 font-bold tracking-tighter uppercase group-hover:underline group-hover:underline-offset-2 decoration-2 group-hover:mr-4">
            Load More
          </div>
          <img
            src={require(`../images/Menu/arrow.png`)}
            alt=""
            className="ml-2 h-5 w-fit"
          />
        </div>
      </div>

      <div id="values" className="val">
        <div className="text-6xl sm:text-9xl font-tabloid font-bold text-black1 uppercase tracking-tighter">
          Values
        </div>
        {/* <div className="my-10 sm:my-20">
          <Slider />
        </div> */}
        <div className="w-32 sm:w-fit mx-auto my-10">
          <img
            src={require(`../images/Menu/bit.png`)}
            alt=""
            className="w-fit"
          />
        </div>
      </div>

      <div className="process">
        <div
          id="process"
          className="text-6xl sm:text-9xl font-tabloid font-bold text-black1 uppercase tracking-tighter"
        >
          PROCESS
        </div>
        {data.map((item, i) => {
          const title = `Lorem${i + 1}`;
          return (
            <div key={title}>
              <div className="my-4 sm:my-10 h-[0.05rem] w-full bg-black "></div>

              <div
                className="flex items-center sm:items- justify-between text-black"
                onClick={() => handleClick(`Lorem${i + 1}`, ob[title])}
              >
                <div className="font-tabloid font-bold text-xs sm:text-lg tracking-widest uppercase cursor-pointer">
                  {item.title}
                </div>
                {ob[title] ? (
                  <div className="inline-block sm:hidden sm:mt-4 w-5 bg-black h-[0.1rem] ml-20 mr-5 cursor-pointer" />
                ) : (
                  <img
                    src={require("../images/plus.png")}
                    alt=""
                    className="inline-block sm:hidden sm:mt-2 h-5 ml-20 mr-5 cursor-pointer"
                  />
                )}

                <div className="hidden  relative sm:flex font-eb  items-start w-[50%]">
                  {/* <div className="non-flex relative w-[40%]"> */}
                  <div className="cursor-pointer w-full">{item.data1}</div>
                  {ob[title] && (
                    <div
                      className={`${
                        item.data1.length > 87 ? "mt-3" : ""
                      } w-[80%] absolute top-8 transition-all ease-in-out duration-1000 delay-300 translate-`}
                    >
                      {item.data2}
                    </div>
                  )}
                  {/* </div> */}
                  {ob[title] ? (
                    <div className="mt-4 w-5 bg-black h-[0.1rem] ml-20 mr-5 cursor-pointer" />
                  ) : (
                    <img
                      src={require("../images/plus.png")}
                      alt=""
                      className="mt-2 h-5 ml-20 mr-5 cursor-pointer"
                    />
                  )}
                </div>
              </div>
              <div className="relative sm:hidden ">
                <div className="w-[75%] cursor-pointer text-xs mt-1">
                  {item.data1}
                </div>
                {ob[title] && (
                  <div
                    className={`w-[75%] mt-3 sm:mt-5 text-xs absolute top-8 transition-all ease-in-out duration-1000 delay-300 translate-`}
                  >
                    {item.data2}
                  </div>
                )}
              </div>
              {ob[title] && <div className="relative mt-20 "></div>}
            </div>
          );
        })}
      </div>

      <div className="team">
        <div className="flex flex-col justify-center md:grid md:grid-cols-2">
          <div
            id="team"
            className="text-4xl sm:text-6xl pr-10 font-tabloid font-bold text-black1 uppercase tracking-tighter"
          >
            Awesome TEAM & LEADERSHIP
          </div>
          <div className="hidden sm:inline-block font-eb text-title text-xl leading-relaxed mt-3">
            We create<span className="text-yellow"> meaningful member</span>{" "}
            experiences online and in the real world from design strategy and
            brand identities to digital platforms.
          </div>
        </div>

        <div className="box">
          <div className="pb-10">
            <img src={require("../images/team1.png")} alt="" className="" />
            <div className="mt-3 sm:mt-5 font-tabloid text-black tracking-tighter font-extrabold  uppercase">
              Timy Borgholm
            </div>
            <div className="mt-1 sm:mt-3 uppercase font-roboto text-xs -tracking-[0.03rem]">
              ui designer
            </div>
          </div>
          <div className="w-[0.01rem] bg-black1 h-68" />
          <div className="pb-10">
            <img src={require("../images/team2.png")} alt="" className="" />
            <div className="mt-3 sm:mt-5 font-tabloid text-black tracking-tighter font-extrabold  uppercase">
              Frans Robison
            </div>
            <div className="mt-1 sm:mt-3 uppercase font-roboto text-xs -tracking-[0.03rem]">
              ui designer
            </div>
          </div>
          <div className="w-[0.01rem] bg-black1 h-68"></div>
          <div className="pb-10">
            <img src={require("../images/team3.png")} alt="" className="" />
            <div className="mt-3 sm:mt-5 font-tabloid text-black tracking-tighter font-extrabold  uppercase">
              Diana Fest
            </div>
            <div className="mt-1 sm:mt-3 uppercase font-roboto text-xs -tracking-[0.03rem]">
              ui designer
            </div>
          </div>
        </div>

        <div className="box1">
          <div className="pb-10">
            <img src={require("../images/team4.png")} alt="" className="" />
            <div className="mt-3 sm:mt-5 font-tabloid text-black tracking-tighter font-extrabold  uppercase">
              Aeshly Denkas
            </div>
            <div className="mt-1 sm:mt-3 uppercase font-roboto text-xs -tracking-[0.03rem]">
              project manager
            </div>
          </div>
          <div className="w-[0.01rem] bg-black1 h-68"></div>
          <div className="pb-10">
            <img src={require("../images/team5.png")} alt="" className="" />
            <div className="mt-3 sm:mt-5 font-tabloid text-black tracking-tighter font-extrabold  uppercase">
              Ryana Dumbl
            </div>
            <div className="mt-1 sm:mt-3 uppercase font-roboto text-xs -tracking-[0.03rem]">
              art derector
            </div>
          </div>
          <div className="w-[0.01rem] bg-black1 h-68"></div>
          <div className="pb-10">
            <img src={require("../images/team6.png")} alt="" className="" />
            <div className="mt-3 sm:mt-5 font-tabloid text-black tracking-tighter font-extrabold  uppercase">
              Mily Strong
            </div>
            <div className="mt-1 sm:mt-3 uppercase font-roboto text-xs -tracking-[0.03rem]">
              graphic designer
            </div>
          </div>
        </div>

        <div className="read">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Nunc orci
          dui, blandit eu suscipit
        </div>

        <div className="hidden mt-10 w-max mx-auto sm:flex items-center cursor-pointer group">
          <div className="font- text-sm text-black1 font-bold tracking-tighter uppercase group-hover:underline group-hover:underline-offset-2 decoration-2 group-hover:mr-4">
            Read More
          </div>
          <img
            src={require(`../images/Menu/arrow.png`)}
            alt=""
            className="ml-2 h-5 w-fit"
          />
        </div>
      </div>

      <div className="want">
        <div className="grid sm:grid-cols-2">
          <div className="text-4xl sm:text-6xl w-[90%] sm:w-[60%] font-tabloid font-bold text-black1 uppercase tracking-tighter leading-relaxed sm:leading-[4rem]">
            WANT TO BE A PART OF OUR TEAM
          </div>
          <div>
            <div className="flex justify-between font-libre text-title text-xl leading-relaxed mt-3">
              <div>
                <input
                  type="text"
                  className="appearance-none border-none outline-none w-[90%] sm:w-fit"
                  placeholder="TELL US WHY"
                />
              </div>
              <div>
                <button type="submit">
                  <img
                    src={require(`../images/Menu/arrow.png`)}
                    alt=""
                    className="sm:ml-2 h-6 w-fit hover:h-5"
                  />
                </button>
              </div>
            </div>
            <div className="h-[0.05rem] w-full bg-black" />
            <div className="mt-4 font-eb text-black tracking-wider pr-10">
              Lorem ipsum dolor sit amet consectetur. Sit vestibulum dictum quam
              malesuada elit leo metus fermentum.
            </div>
          </div>
        </div>
      </div>

      <div className="px-3 md:px-10 lg:px-24 w-full bg-marketing py-20">
        <div className="w-max mx-auto">
          <div className="text-3xl sm:text-6xl font-tabloid  text-black1  tracking-wider">
            Got a<span className="font-semibold"> PROJECT</span>
          </div>
          <div className="mt-2 flex items-center text-3xl sm:text-6xl font-tabloid font-semibold text-black1  tracking-wider">
            <div className="">IN MIND?</div>
            <div className="">
              <button className="ml-2 sm:ml-8 rounded-2xl border-black1 py-2 px-3 border-[0.07rem] hover:border-[0.1rem] text-xs text-black1 font-light font-circular flex items-center">
                Let's Talk
                <img
                  src={require(`../images/Menu/arrow.png`)}
                  alt=""
                  className="ml-2 h-3 w-fit"
                />
              </button>
            </div>
          </div>
        </div>

        <div className="grid sm:grid-cols-3 gap-7 sm:gap-[4rem] w-[90%] sm:w-[75%] mx-auto mt-14">
          <div className="text-sm">
            <div className="w-full h-[0.08rem] bg-black1"></div>
            <div className="flex justify-between mt-2 items-center cursor-pointer">
              <div className="font-circular font-semibold tracking-wider text-black1">
                Linkedin
              </div>
              <div className="">
                {" "}
                <img
                  src={require(`../images/link.png`)}
                  alt=""
                  className="h-8 w-fit"
                />
              </div>
            </div>
            <div className="font-circular text-label mt-1 cursor-pointer w-fit">
              @Amkamtech_ecommerce
            </div>
          </div>
          <div className="text-sm">
            <div className="w-full h-[0.08rem] bg-black1"></div>
            <div className="flex justify-between mt-2 items-center cursor-pointer">
              <div className="font-circular font-semibold tracking-wider text-black1">
                Instagram
              </div>
              <div className="">
                {" "}
                <img
                  src={require(`../images/insta.png`)}
                  alt=""
                  className="h-8 w-fit"
                />
              </div>
            </div>
            <div className="font-circular text-label mt-1 cursor-pointer w-fit">
              @Amkamtech_ecommerce
            </div>
          </div>
          <div className="text-sm">
            <div className="w-full h-[0.08rem] bg-black1"></div>
            <div className="flex justify-between mt-2 items-center cursor-pointer">
              <div className="font-circular font-semibold tracking-wider text-black1">
                Twitter
              </div>
              <div className="">
                {" "}
                <img
                  src={require(`../images/twiter.png`)}
                  alt=""
                  className="h-8 w-fit"
                />
              </div>
            </div>
            <div className="font-circular text-label mt-1 cursor-pointer w-fit">
              @Amkamtech_ecommerce
            </div>
          </div>
        </div>
        <div className=""></div>
      </div>
    </>
  );
};

export default HamburgerMenuPage;
