import React from "react";
import DirectionalIcon from "../../assets/images/blackdirectional.svg";
import Linkdin from "../../assets/images/linkdin.svg";
// import InstaGram from "../../assets/images/insta.svg"
import Twitter from "../../assets/images/twitter.svg";
import "./style.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer_section">
      <div className="container">
        <div className="row">
          <div className="footer_wrapper">
            <div className="col-12 d-flex justify-content-center">
              <h2>
                Got a{" "}
                <b>
                  {" "}
                  PROJECT{" "}
                  <span className="d-flex align-items-center">
                    IN MIND?
                    <button>
                      <span>Let’s Talk</span>
                      <img
                        className="ms-2"
                        src={DirectionalIcon}
                        alt="arrowicon"
                      />
                    </button>
                  </span>
                </b>
              </h2>
            </div>
            <ul className="got_a_project" style={{ fontFamily: "EB Garamond" }}>
              <li>
                <p>
                  EMAIL US
                  <span className="d-block">support@Amkamtech.com</span>
                </p>
              </li>
              <li>
                <p>
                  SPEAK WITH US<span className="d-block">+61 405 210 438 </span>
                </p>
              </li>
              <li>
                <p>
                  HEADQUARTERS
                  <span className="d-block">Melbourne, Australia</span>
                </p>
              </li>
            </ul>
            <div className="col-12 d-flex justify-content-end">
              <a target="blank" href="https://www.linkedin.com/in/sahilmishra/">
                <span className="img_container">
                  <img src={Linkdin} alt="linkdin" />
                </span>
              </a>
              <a target="blank" href="https://twitter.com/">
                <span className="img_container ms-4">
                  <img src={Twitter} alt="twitter" />
                </span>
              </a>
            </div>
            <div className="flex gap-6 justify-center mt-10">
              <Link to="/privacypolicy">
                <h1 className="hover:underline hover:text-black font-semibold">
                  @ Privacy Policy
                </h1>
              </Link>
              <Link to="/legal">
                <h1 className="hover:underline hover:text-black font-semibold">
                  @ Legal Information
                </h1>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
