import React from "react";

import Navbar from "../components/navBar/index";
import Footer from "../components/Footer";

const LegalPage = () => {
  return (
    <>
      <div className="bg-black   w-full h-full flex flex-col ">
        <div>
          <Navbar />
        </div>
        <div className="mt-24 text-white px-2"></div>
      </div>
      {/* ...................................... */}
      <div
        className={` flex flex-col items-center py-14 font-medium gap-10 px-2  w-full h-full `}
      >
        <h1 className=" font-extrabold text-4xl mt-9">Legal Information</h1>
        <div className="flex flex-col w-full gap-10 mt-10 lg:max-w-6xl">
          {/* About */}
          <div className="space-y-4">
            <h2>Last Updated on : 29 May 2023 </h2>
            <h2>Place: </h2>
          </div>
          {/* Information */}
          <div className="flex flex-col gap-8">
            <p>
              This (the “Agreement”) is entered into at on (the “Effective
              Date”).
            </p>
            <p>
              Thank you for using . If this document is not in a language that
              you understand, you shall contact at the email . Failure to do so
              within 24 (Twenty-Four) hours from the time of receipt of this
              document and your acceptance of this document by clicking “I
              ACCEPT” button shall be considered as your understanding of this
              document.
            </p>
            <p>
              This electronic record is generated by a computer system and does
              not require any physical or digital signatures. By clicking on the
              “I ACCEPT” button on this electronic contract, you are consenting
              to be bound by this User Agreement. Please ensure that you read
              and understand all the provisions of this document before you
              start using the , as you shall be bound by all the terms and
              conditions herein upon clicking on the “ACCEPT & CONTINUE” button
              on this electronic contract. If you do not agree or accept any of
              the Terms and Conditions contained in this Privacy Policy, then
              please do not use the ’s digital platform or avail any of the
              services being provided therein.
            </p>
            <p>
              {" "}
              or any of its affiliates or associate companies or subsidiaries
              (hereafter collectively referred as , “Amkamtech”, “we”, “us”, or
              “our”), prescribes this Privacy Policy (the “Privacy Policy” /
              “Policy”), describes how we collect, use, and disclose your
              Personal Information when you visit or use our website, (the
              “Site”), the , email plugins, the chrome extension and any other
              website or mobile application or plugins that links to this
              Privacy Policy in Our capacity as a controller of your Personal
              Information.
            </p>
            <p>
              This Privacy Policy explains what information of yours will be
              collected when you access the Services and Products, how such
              Personal Information will be used, and how you can control the
              collection, correction and/or deletion of your Personal
              Information. We will not use or share your Personal Information
              with anyone except as described in this Privacy Policy. The use of
              information collected through our Sites shall be limited to the
              purposes under this Privacy Policy and our Terms of Service (the
              “Terms”) to customers. Capitalised terms not specifically defined
              herein shall have the same meaning as ascribed to them in the
              Terms.
            </p>
            <p>
              Supplemental clauses in the privacy policy or documents that may
              be posted on the Site from time to time are hereby expressly
              incorporated herein by reference. We reserve the right, in our
              sole discretion, to make changes or modifications to the Privacy
              Policy time to time.{" "}
            </p>
            <p>
              <span className="font-extrabold">Questions or concerns?</span>{" "}
              Reading this privacy notice will help you understand your privacy
              rights and choices. If you do not agree with our policies and
              practices, please do not use our Services. If you still have any
              questions or concerns, please contact us at{" "}
            </p>
          </div>
          {/*................. SUMMARY OF KEY POINTS..........*/}
          <div className="flex flex-col gap-8">
            <h1 className="text-xl font-extrabold ">SUMMARY OF KEY POINTS</h1>
            <div className="flex flex-col gap-6">
              <p>
                <span className="font-extrabold">
                  What personal information do we process?{" "}
                </span>
                When you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with and its
                Services, the choices you make, and the products and features
                you use.
              </p>
              <p>
                <span className="font-extrabold">
                  Do we process any sensitive personal information?{" "}
                </span>
                We may process sensitive personal information when necessary
                with your consent or as otherwise permitted by applicable law.
              </p>
              <p>
                <span className="font-extrabold">
                  Do you receive any information from third parties?{" "}
                </span>
                We may receive information from public databases, marketing
                partners, social media platforms, and other outside sources.
              </p>
              <p>
                <span className="font-extrabold">
                  How do you process my information?{" "}
                </span>
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so.
              </p>
              <p>
                <span className="font-extrabold">
                  In what situations and with which parties do we share personal
                  information?{" "}
                </span>
                We may share information in specific situations and with
                specific third parties.
              </p>
              <p>
                <span className="font-extrabold">
                  In what situations and with which parties do we share personal
                  information?{" "}
                </span>
                We may share information in specific situations and with
                specific third parties.
              </p>
              <p>
                <span className="font-extrabold">
                  How do we keep your information safe?{" "}
                </span>
                We have organizational and technical processes and procedures in
                place to protect your personal information. However, no
                electronic transmission over the internet or information storage
                technology can be guaranteed to be 100% secure, so we cannot
                promise or guarantee that hackers, cybercriminals, or other
                unauthorized third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information.
              </p>
              <p>
                <span className="font-extrabold">What are your rights? </span>
                Depending on where you are located geographically, the
                applicable privacy law may mean you have certain rights
                regarding your personal information.
              </p>
              <p>
                <span className="font-extrabold">
                  How do I exercise my rights?{" "}
                </span>
                The easiest way to exercise your rights is by sending an email
                to us at: or by contacting us. We will consider and act upon any
                request in accordance with applicable data protection laws.
              </p>
              <p>
                <span className="font-extrabold"> </span>
              </p>
            </div>
          </div>
          {/* ...........Overview.................. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">Overview</h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                This Privacy Policy applies to the Users of Services anywhere in
                the India, including the Users of its features and other
                services. This Privacy Policy describes how and its affiliates
                collects and use your personal data.{" "}
              </li>
              <li>
                Is a digital property of . We are committed to protecting and
                respecting your Privacy. We do collect your personal information
                and process your personal data in accordance with the
                Information Technology Act, 2000 (also known as ITA-2000, or the
                IT Act) and other national and state laws which relate the
                processing of your personal data.
              </li>
              <li>
                We collect your information in order to provide and continually
                improve our services. Our Privacy Policy is subject to change at
                any time without a notice, to make sure you are aware of any
                changes, please review this Policy periodically. All the
                Partners and Independent Partners who are working with or for
                us, or who have access to personal information, will be expected
                to read and comply with this Policy. No third-party may access
                or process sensitive personal information held by us without
                having first entered into a Confidentiality Agreement.
              </li>
            </ul>
          </div>
          {/* ......Collection of your Personal Data............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">
              Collection of your Personal Data
            </h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                Personal Data: While using Our Service, we may ask You to
                provide Us with certain personally identifiable information that
                can be used to contact or identify You. Personally identifiable
                information may include, but is not limited to:
              </li>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Usage Data</li>
            </ul>
          </div>
          {/* ......Usage Data............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">Usage Data</h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                Usage Data is collected automatically when using the Service.
              </li>
              <li>
                Usage Data may include information such as Your Devices Internet
                Protocol address (e.g. IP address), browser type, browser
                version, the pages of our Service that You visit, the time and
                date of Your visit, the time spent on those pages, unique device
                identifiers and other diagnostic data.
              </li>
              <li>
                When You access the Service by or through a mobile device, we
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, your mobile
                device unique ID, the IP address of Your mobile device, your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </li>
              <li>
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </li>
            </ul>
          </div>
          {/* ......Sources of Personal Data............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">
              Sources of Personal Data
            </h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                We obtain the categories of personal information listed above
                from the following categories of sources:
              </li>
              <li>
                Directly from You. For example, from the forms You complete on
                our Service, preferences You express or provide through our
                Service, or from Your purchases of our Products
              </li>
              <li>
                Automatically from You. For example, through cookies We or our
                Service Providers set on Your Device as You navigate through our
                Service.
              </li>
              <li>
                From Service Providers. For example, third-party vendors to
                monitor and analyze the use of our Service, third-party vendors
                for payment processing, or other third-party vendors that We use
                to provide the Service to You.
              </li>
            </ul>
          </div>
          {/* ......Use of your Personal Data............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">
              Use of your Personal Data
            </h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li className="text-lg font-semibold">
                The Company may use Personal Data for the following purposes:
              </li>
              <li>
                To provide and maintain our Service, including to monitor the
                usage of our Service.
              </li>
              <li>
                To manage Your Account: to manage Your registration as a user of
                our products. The Personal Data You provide can give You access
                to different functionalities of the Products & Service that are
                available to You as a registered user.
              </li>
              <li>
                For the performance of a contract: the development, compliance
                and undertaking of the purchase contract for the products, items
                or services You have purchased or of any other contract with Us.
              </li>
              <li>
                To contact You: To contact You by email, telephone calls, SMS,
                or other equivalent forms of electronic communication, such as a
                mobile {"application's"} push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </li>
              <li>
                To provide You with news, special offers and general information
                about other products, services and events which we offer that
                are similar to those that you have already purchased or enquired{" "}
              </li>
              <li>
                To manage Your requests: To attend and manage Your requests to
                Us.
              </li>
              <li>
                For business transfers: We may use Your information to evaluate
                or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by Us about our Service users is among the assets transferred.
              </li>
              <li>
                For other purposes: We may use Your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our Service, products, services,
                marketing and your experience.
              </li>
            </ul>
          </div>
          {/* ......Sharing of your Information............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">
              Sharing of your Information
            </h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                We may also share your Personal Information externally with
                other third-party service providers, which might include, but is
                not necessarily limited to the companies who provide us with
                third party services, payment provider, business entities that
                provide email address management and communication contact
                services, network equipment and application management providers
                and hosting entities, credit and debit card payment gateways and
                processors and other service providers which may be involved in
                the other types of services and activities otherwise discussed
                in this privacy policy.
              </li>
              <li>
                Currently, we use best method to protect your data and
                information which you provide to us by visiting on our website.
                Although no method of transmission over the Internet or
                electronic storage is 100% secure, we follow all safety
                requirements and implement additional generally accepted
                industry standards. Your credit card information will also be
                subject to our payment processor’s privacy policy in addition to
                ours. PLEASE READ THE PRIVACY POLICY ON THE WEBSITE OF ANY
                PAYMENT PROCESSOR THAT YOU ARE DIRECTED TO REGARDING THE USE,
                STORAGE AND PROTECTION OF YOUR CREDIT CARD INFORMATION BEFORE
                SUBMITTING ANY CREDIT CARD INFORMATION.
              </li>
              <li className="text-lg font-semibold">
                We may also share your Personal Information for the purposes
                including but not limited to the below:
              </li>
              <div className="px-4 space-y-4">
                <li>When you agreed to the sharing</li>
                <li>
                  If you are a business customer and agreed to receive marketing
                  communications. Note that we do not share Personal Information
                  about consumers with third parties for their own marketing
                  purposes unless the consumer agreed to that sharing.
                </li>
                <li>
                  When we are required to provide information in response to a
                  subpoena, court order, or other applicable law or legal
                  process.
                </li>
                <li>
                  When we have a good faith belief that the disclosure is
                  necessary to prevent or respond to fraud, defend our apps or
                  websites against attacks, or protect the property and safety
                  of , our customers and users, or the public.
                </li>
                <li>
                  If we merge with or are acquired by another company, sell ’s
                  websites, apps, or business units, or if all or a substantial
                  portion of our assets are acquired by another company. In
                  those cases, your information will likely be one of the assets
                  that is transferred.
                </li>
              </div>
              <li>
                We display personal testimonials of customers on our Site in
                addition to other endorsements. With your consent, we may post
                your testimonial along with your name. If you wish to update or
                delete your testimonial, you can contact us.
              </li>
              <li>
                Further, you agree that may transfer the anonymous information
                we collect in the event of any corporate restructuring or any
                sale, assignment, or transfer of substantially all of its
                rights.
              </li>
              <li className="text-lg font-semibold">
                We do not sell, trade, or otherwise transfer your Personal
                Information except in accordance with this Policy. We do not
                share any information that directly identifies you with any
                third party, except in the following limited cases:
              </li>
              <div className="px-4 space-y-4">
                <li>
                  As required to comply with applicable law or regulation, or to
                  comply with law enforcement;
                </li>
                <li>To respond to claims and/or legal process;</li>
                <li>
                  To protect our rights or our property, or to enforce our Terms
                  of Service;
                </li>
                <li>
                  To help ensure the safety of the public or an individual;
                </li>
                <li>
                  To prevent behavior that is (or that we think may be) illegal
                  or unethical;
                </li>
                <li>With your consent, or at your request or direction; or</li>
                <li>As otherwise set forth in this Policy.</li>
              </div>
              <li>
                Some elements on the Site, such as any sponsored links
                advertising, are supplied to us by third parties under contract.
                We may supply some of your Personal Information to those third
                parties.
              </li>
            </ul>
          </div>
          {/* ......Retention of your Personal Data............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">
              Retention of your Personal Data
            </h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </li>
              <li>
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </li>
            </ul>
          </div>
          {/* ......Storage & Transfer of your Personal Data............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">
              Storage & Transfer of your Personal Data
            </h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                Your Personal Information and files including their backups are
                stored on ’s servers and the servers of companies we hire to
                provide services to Us.
              </li>
              <li>
                Your information, including Personal Data, is processed at the
                {"Company's"} operating offices and in any other places where
                the parties involved in the processing are located. It means
                that this information may be transferred to other country and
                maintained on their computers located outside of Your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ than those from Your
                jurisdiction.
              </li>
              <li>
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
              </li>
              <li>
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </li>
            </ul>
          </div>
          {/* ......Marketing opt outs............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">Marketing opt outs</h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                Will not send you any unsolicited marketing or promotional email
                without your permission. Even after you have given permission
                you may opt-out of receiving such marketing or promotional
                emails by following the instructions in those messages. Please
                note that, we may continue to send you essential messages such
                as updates and notifications related to the Services you have
                subscribed to or information about your account.
              </li>
              <li>
                Please note that any opt out is specific to the browser You use.
                You may need to opt out on every browser that You use.
              </li>
              <li>
                Website: You can opt out of receiving ads that are personalized
                as served by our Service Providers by following our instructions
                presented on the Service:
              </li>
              <li>
                The opt out will place a cookie on Your computer that is unique
                to the browser You use to opt out. If you change browsers or
                delete the cookies saved by your browser, You will need to opt
                out again.
              </li>
              <li className="text-lg font-semibold">
                Mobile Devices: Your mobile device may give You the ability to
                opt out of the use of information about the apps You use in
                order to serve You ads that are targeted to Your interests:
              </li>
              <div className="px-4 space-y-2">
                <li>
                  {'"opt out of Interest-Based Ads"'} or{" "}
                  {'"opt out of Ads Personalization"'} on Android devices
                </li>
                <li>{'"Limit Ad Tracking"'} on iOS devices</li>
              </div>
            </ul>
          </div>

          {/* ......Browsing Cookies ............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">Browsing Cookies</h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li className="text-lg font-semibold">
                Tracking Technologies and Cookies :
              </li>
              <div className="px-4 space-y-4">
                <li>
                  We use Cookies and similar tracking technologies to track the
                  activity on Our Service and store certain information.
                  Tracking technologies used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze Our
                  Service. The technologies We use may include:
                </li>
                <li>
                  Cookies or Browser Cookies. A cookie is a small file placed on
                  Your Device. You can instruct Your browser to refuse all
                  Cookies or to indicate when a Cookie is being sent. However,
                  if You do not accept Cookies, You may not be able to use some
                  parts of our Service. Unless you have adjusted Your browser
                  setting so that it will refuse Cookies, our Service may use
                  Cookies.
                </li>
                <li>
                  Web Beacons. Certain sections of our Service and our emails
                  may contain small electronic files known as web beacons (also
                  referred to as clear gifs, pixel tags, and single-pixel gifs)
                  that permit the Company, for example, to count users who have
                  visited those pages or opened an email and for other related
                  website statistics (for example, recording the popularity of a
                  certain section and verifying system and server integrity).
                </li>
                <li>
                  Cookies can be {'"Persistent"'} or {'"Session"'} Cookies.
                  Persistent Cookies remain on Your personal computer or mobile
                  device when You go offline, while Session Cookies are deleted
                  as soon as You close Your web browser.
                </li>
                <li>
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services. These Cookies allow us to remember choices You
                  make when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                </li>
                <li>
                  For more information about the cookies we use and your choices
                  regarding cookies, please visit our Cookies Policy or the
                  Cookies section of our Privacy Policy.
                </li>
              </div>
            </ul>
          </div>
          {/* ......Connecting your Email Account via social media............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">
              Connecting your Email Account via social media
            </h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                You can choose to provide explicit consent to connect your email
                accounts with your Account. Once connected, the File Website/
                App will securely access and analyse the relevant contents
                related to the Services provided by . We encourage you to review
                your Personal Information prior to signing in through the
                applicable service. does not under any circumstances store your
                email messages, email messages headers, message bodies, or
                attachments. only accesses your email account for scanning
                purposes and displays information immediately as it is returned.
                requests read-only access to your email account and will not
                attempt to alter or modify your email account or email messages
                in any way except as otherwise agreed by you through additional
                consent to write permission in your account with respect to the
                services from you are opting.
              </li>
              <li>
                You can choose to enable us to access one or more of your email
                accounts by connecting such account(s) with your account. If you
                connect your email account, the Site, the website will
                automatically access and analyse the contents of emails, from a
                small list of whitelisted senders that comprise of third parties
                providing you services, in your email account(s) on an ongoing
                basis for the purpose of identifying documents related to the
                applicable Services availed by you, may contain recent account
                statements, transactions, or linked services. Once linked, the
                applicable information will then be extracted from your emails
                into your account so that you may access the same through the
                Site on mobile and desktop.
              </li>
              <li>
                Access to the email account/s is authorized through the email
                provider’s access mechanism. This information will be used to
                enable the Site to automatically access the applicable and
                relevant information to analyse, extract, and store information
                securely from such accounts for use in the Site.
              </li>
              <li>
                You will be asked in each case whether you want to connect your
                email account. You are free to de-link your email connection
                with at any time. You may manage your connections to email and
                de-link the same at any time from the Site. For example, Google
                Mail’s permission settings are located at:
                myaccount.google.com/permissions. A user de-link the access to
                their email any time they wish at this URL.
              </li>
              <li>
                You may choose to enable or log in to our Services via various
                online services, including social network services like
                Facebook. Our Services also may enable you to access social
                networking services such as Facebook, Twitter, or Instagram
                (collectively, “Social Network”) directly or indirectly through
                our Services. In all such cases, any Personal Information that
                you share with such social networking services shall be governed
                by privacy policy of the respective Social Network.
              </li>
            </ul>
          </div>
          {/* ......Privacy of Children............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">Privacy of Children</h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                We recognize the importance of {"children's"} safety and
                privacy. We do not request, or knowingly collect, any personally
                identifiable information from children under the age of 16. is
                very sensitive to privacy issues regarding children.
              </li>
            </ul>
          </div>
          {/* ......Security & Safety............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">Security & Safety</h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                Your Personal Information is extremely important to us. Your
                information resides on a secure server that only selected
                personnel have access to. Promotional and referral codes are
                random alphanumeric sequences that are encrypted. In addition to
                our precautions, it is important that you do not share your
                password with anyone. Although we try our best to protect your
                information, we cannot eliminate security risks associated with
                Personal Information.
              </li>
              <li className="text-lg font-semibold">
                The right to delete Personal Data. You have the right to request
                the deletion of Your Personal Data, subject to certain
                exceptions. Once We receive and confirm Your request, We will
                delete (and direct Our Service Providers to delete) Your
                personal information from our records, unless an exception
                applies. We may deny Your deletion request if retaining the
                information is necessary for Us or Our Service Providers to :
              </li>
              <div className="px-4 space-y-4">
                <li>
                  Complete the transaction for which We collected the personal
                  information, provide a good or service that You requested,
                  take actions reasonably anticipated within the context of our
                  ongoing business relationship with You, or otherwise perform
                  our contract with You.
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity, or prosecute those
                  responsible for such activities.
                </li>
                <li>
                  Debug products to identify and repair errors that impair
                  existing intended functionality.
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when the{" "}
                  {"information's"}
                  deletion may likely render impossible or seriously impair the
                  {"research's"} achievement, if You previously provided
                  informed consent.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  consumer expectations based on Your relationship with Us
                </li>
                <li>Comply with a legal obligation.</li>
                <li>
                  Make other internal and lawful uses of that information that
                  are compatible with the context in which You provided it.
                </li>
              </div>
            </ul>
          </div>
          {/* ......Changes to this Privacy Policy............. */}
          <div className="flex flex-col gap-6">
            <h1 className="text-2xl font-extrabold">
              Changes to this Privacy Policy
            </h1>
            <ul className="flex flex-col gap-6 px-8 list-disc">
              <li>
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
              </li>
              <li>
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                {'"Last updated" '}date at the top of this Privacy Policy.
              </li>
              <li>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </li>
            </ul>
          </div>
          {/* ......Contact us............. */}

          {/* Accept */}
        </div>
        <div className="w-full h-[50px]"></div>
      </div>

      <div id="footer" className="mt-20">
        <Footer />
      </div>
    </>
  );
};

export default LegalPage;
