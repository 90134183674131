import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import Linkdin from "../../assets/images/linkdin.svg";
import BlackDirectional from "../../assets/images/blackdirectional.svg";
import Twitter from "../../assets/images/twitter.svg";
import Navbar from "../navBar/index";
import emailjs from "@emailjs/browser";
function CareerPage() {
  const [cursorX, setCursorX] = useState(-100);
  const [cursorY, setCursorY] = useState(-100);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    alert("Your Mail have been Send");
  };

  useEffect(() => {
    const cursorRounded = document.querySelector(".rounded");
    const handleMouseMove = (e) => {
      setCursorX(e.clientX);
      setCursorY(e.clientY);
      cursorRounded.style.transition = `transform 0.12s`;
    };

    const handleScroll = () => {
      setCursorY((prevY) => prevY);
    };

    const handleResize = () => {
      setCursorX(-100);
      setCursorY(-100);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [cursorX, cursorY]);

  return (
    <div className="body career_page_section">
      <div
        class="cursor rounded"
        style={{
          left: `${cursorX - 13}px`,
          top: `${cursorY - 13}px`,
          transform: "0.1s",
        }}
      ></div>

      <Navbar />

      <div className="container">
        <div className="row mt-lg-0 mt-md-5 mt-sm-5 mt-5">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <div className="career_text">
              <h2 class="career_heading mt-0">
                Think we're a good fit? get in touch
                <div className="card_heading_line"></div>
              </h2>
              <ul className="social_icon_wrapper">
                <li className="socila_meadia_heading">Social media</li>
                <li className="d-flex align-items-center mt-3">
                  <a
                    target="blank"
                    href="https://www.linkedin.com/in/sahilmishra/"
                  >
                    <span className="icon_social_link">
                      <img src={Linkdin} alt="linkdin" />
                    </span>
                  </a>
                  <a
                    target="blank"
                    href="https://twitter.com/mail4agam?s=11&t=kSYEf3dJYG8balbEtzVTtw"
                  >
                    <span className=" ms-3 icon_social_link">
                      <img src={Twitter} alt="twitter" />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="form_container">
              <form ref={form} onSubmit={sendEmail}>
                <input type="text" placeholder="Name" name="user_name" />
                <input placeholder="Email" name="user_email" />
                <textarea
                  className="w-full border-b-2 border-black   focus:outline-none bg-[#ececec]"
                  rows="8"
                  placeholder="Message"
                  name="message"
                ></textarea>
                <div className="msg_container d-flex justify-content-end">
                  <button className="d-flex align-items-center" type="submit">
                    Send message
                    <img
                      className="ms-2"
                      src={BlackDirectional}
                      alt="BlackDirectional"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerPage;
