import React, { useState , useEffect } from "react";
import "./style.css";
import Portfolio from "../../assets/images/portfolio.svg";
// import MenuIcon from "../../assets/images/menu_icon.svg";
import Standard from "../../assets/images/standard.svg";
import NormForm from "../../assets/images/normform.svg";
import Perfect from "../../assets/images/perfect.svg";
import Blueapp from "../../assets/images/blueapp.svg";
import Greenery from "../../assets/images/greenery.svg";
import Summerseez from "../../assets/images/summerseez.svg";
import Summerbreeze from "../../assets/images/summerbreeze.svg";
import Cityvibe from "../../assets/images/cityvibe.svg";
import Foodexpert from "../../assets/images/foodexpert.svg";
import LoadMore from "../../assets/images/loadMore.svg";
import India from "../../assets/images/india.svg";
import Usa from "../../assets/images/usa.svg";
import Uk from "../../assets/images/uk.svg";
import Ireland from "../../assets/images/ireland.svg";
import Uae from "../../assets/images/uae.svg";
import Australia from "../../assets/images/australia.svg";
import France from "../../assets/images/france.svg";
import Oman from "../../assets/images/oman.svg";
import Bing from "../../assets/images/bing.svg";
import Google from "../../assets/images/google.svg"
import MyLinkdin from "../../assets/images/mylinkdin.svg";
import Snapchat from "../../assets/images/snapchat.svg";
import Youtube from "../../assets/images/youtube.svg"
import Meta from "../../assets/images/meta.svg";
import Micro from "../../assets/images/micro.svg";
import Amazon from "../../assets/images/amazon.svg"
import MyTwitter from "../../assets/images/mytwitter.svg"
import Apple from "../../assets/images/apple.svg"
import Disney from "../../assets/images/disney.svg"
import Quora from "../../assets/images/quora.svg"
import Retail from "../../assets/images/retail.svg"
import Fmcg from "../../assets/images/fmcg.svg"
import Edtech from "../../assets/images/edtech.svg"
import Web3 from "../../assets/images/web3.svg"
import Marttech from "../../assets/images/marttech.svg"
import Fashion from "../../assets/images/fashion.svg"
import Fintech from "../../assets/images/fintech.svg"
import Automobiles from "../../assets/images/automobiles.svg"
import ConsumerElectronics from "../../assets/images/consumerelectronics.svg"

 
function Projects() {
  const [numItems, setNumItems] = useState(3);
  const [Industry,SetIndustry]=useState(true);
  const [Partnership,SetPartnership]=useState(false);
  const [Geography,SetGeography]=useState(false);
  const [highlight,setHighlight]=useState("ind");
   
  const handleLoadMore = () => {
    setNumItems(numItems + 3);
  };
  const industrycards=[
    {title:"Retail",
     desc:"",
      img:Retail},
    {title:"FMCG",
     desc:"",
      img:Fmcg},
    {title:"Education/EdTech",
     desc:"",
      img:Edtech},
    {title:"FinTech",
     desc:"",
      img:Fintech},
    {title:"Automobiles",
     desc:"",
      img:Automobiles},
    {title:"Consumer E.",
     desc:"",
      img:ConsumerElectronics},
    {title:"Web 3.0",
     desc:"",
      img:Web3},
    {title:"Martech",
     desc:"",
      img:Marttech},
    {title:"Fashion",
     desc:"",
      img:Fashion}
];
  
  const geographycards=[
      {country:"India",img:India},
      {country:"USA",img:Uk},
      {country:"UK",img:Usa},
      {country:"Ireland",img:Ireland},
      {country:"UAE",img:Uae},
      {country:"Singapore",img:Summerbreeze},
      {country:"Australia",img:Australia},
      {country:"France",img:France},
      {country:"Oman",img:Oman}
    ]

    const partnership = [
      {name:"GOOGLE", img:Google},
      {name:"LINKEDIN", img:MyLinkdin},
      {name:"BING", img:Bing},
      {name:"SNAPCHAT", img:Snapchat},
      {name:"YOUTUBE", img:Youtube},
      {name:"META", img:Meta},
      {name:"AMAZON", img:Amazon},
      {name:"QUORA", img:Quora},
      {name:"MICROSOFT", img:Micro},
      {name:"TWITTER", img:MyTwitter},
      {name:"DISNEY", img:Disney},
      {name:"Apple", img:Apple},
      ];


  return (
    <div className="project_page_section md:mt-[10vh] mt-[25vh]">
      <div className="project_section">
        <div className="container">
          <div className="row">
            <div className="col-12 pb-4 project_col">
              <h2 
              style={{fontFamily:"tabloid"}}
              className="project_title">Projects</h2>
              <div className="project_line"></div>
            </div>
            <div className="col-12 project_col d-flex justify-content-between align-items-center mt-4">
              <div className="submenu">
                <ul className="d-flex" style={{fontFamily:"Roboto Condensed"}}>
                  <li> <button onClick={()=>{
                    setHighlight("ind");
                    SetIndustry(true);
                    SetGeography(false);
                    SetPartnership(false);}} className={highlight==="ind"?` underline`:``}>INDUSTRY</button></li>
                  <li><button onClick={()=>{
                    setHighlight("part");
                    SetIndustry(false);
                    SetGeography(false);
                    SetPartnership(true);}} className={highlight==="part"?`ps-lg-4 ps-md-4 ps-sm-2 ps-0 underline`:`ps-lg-4 ps-md-4 ps-sm-2 ps-0`}>PARTNERSHIPS</button></li>
                  <li><button onClick={()=>{
                    setHighlight("geo");
                    SetIndustry(false);
                    SetGeography(true);
                    SetPartnership(false);}} className={highlight==="geo"?`ps-lg-4 ps-md-4 ps-sm-2 ps-0 underline`:`ps-lg-4 ps-md-4 ps-sm-2 ps-0`}>GEOGRAPHY</button></li>
                </ul>
              </div>
              {/* <div className="dotted_icon">
                <img src={MenuIcon} alt="menuicon" />
              </div> */}
            </div>
            {/* <div className="row"> */}
            {
              Industry?
              industrycards.slice(0, numItems).map((item,idx)=>{
              return(
              <div className={`col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-start`}>
                <div className={`projects_img_content industry_content  ${((idx+1)%3==2||(idx+1)%3==0) ?  "ms-lg-5":""}`}>
                  <img src={item.img} alt="portofolio" />
                  <div className="header_underline"></div>
                  <h2 style={{fontFamily:"tabloid"}}>{item.title}</h2>
                  <div className="headerwidth"></div>
                  <h3></h3>
                  <p>{item.desc}</p>
                </div>
              </div>
              )}):(
              Geography?
              geographycards.slice(0, numItems).map((item,idx)=>{
              return (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-start">
                <div className={`projects_img_content ${((idx+1)%3==2||(idx+1)%3==0) ?  "ms-lg-5":""}`}>
                  <img className="contry_icon" src={item.img} alt="portofolio" />
                  <div className="header_underline"></div>
                  <h2 style={{fontFamily:"tabloid"}}>{item.country}</h2>
                  <div className="headerwidth"></div>
                  <h3></h3>
                  <p></p>
                </div>
              </div>
            )}):
            partnership.slice(0, numItems).map((item,idx)=>{
              return (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-start">
                <div className={`projects_img_content ${((idx+1)%3==2||(idx+1)%3==0) ?  "ms-lg-5":""}`}>
                  <img className="brand_icon" src={item.img} alt="portofolio" />
                  <div className="header_underline"></div>
                  <h2 style={{fontFamily:"tabloid"}}>{item.name}</h2>
                  <div className="headerwidth"></div>
                  <h3></h3>
                  <p></p>
                </div>
              </div>
            )}))}
            {/* </div> */}
            <div style={{fontFamily:"tabloid"}} className="col-lg-12 d-flex justify-content-center mt-5 pt-5 mb-5 pb-5">
            {Industry && numItems < industrycards.length && (<button onClick={handleLoadMore} className="d-flex align-items-center load_more_btn">Load More<img className="loadmore_img" src={LoadMore} alt="loadmore"/></button>)}
            {Geography && numItems < geographycards.length && (<button onClick={handleLoadMore} className="d-flex align-items-center load_more_btn">Load More<img className="loadmore_img" src={LoadMore} alt="loadmore"/></button>)}
            {Partnership && numItems < partnership.length && (<button onClick={handleLoadMore} className="d-flex align-items-center load_more_btn">Load More<img className="loadmore_img" src={LoadMore} alt="loadmore"/></button>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
