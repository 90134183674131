import React from "react";
import Navbar from "../components/navBar/index";
import Footer from "../components/Footer";
import Mobile from "../images/mobile.png";
// import { Link } from "react-router-dom";

const MobileAppsPage = () => {
  return (
    <>
      <div className="bg-black   w-full h-full flex flex-col ">
        <div>
          <Navbar />
        </div>
        <div className="mt-36 text-white px-2">
          <div className="flex flex-col gap-4 items-center lg:items-start lg:px-32">
            <h1 className="text-lg  lg:text-4xl font-bold">Mobile Apps</h1>
            <div className="flex">
              <div className="w-24 h-1 bg-blue"></div>
              <div className="w-24 h-1 bg-white"></div>
            </div>
            <div className="w-full text-base font-semibold flex flex-col items-center lg:items-start lg:text-5xl mt-10 ">
              <h1 className="text-center">
                We have developed expertise in developing a wide
              </h1>
              <p>range of mobile and tablet applications</p>
            </div>
            <p className="font-extralight">
              Amkamtech offers comprehensive mobility solutions, developing
              customized mobile and tablet apps catering to unique requirements
              of each brand and business. With growing popularity of smartphones
              and technology advancement, it has become essential for business
              to offer smooth mobile experiences to their customers.
            </p>
            <div className="flex justify-center w-full  gap-4 py-10">
              <img
                src={Mobile}
                alt="wen design & development"
                className="w-[200px] lg:w-[300px]"
              />
            </div>
            <div className="w-full flex justify-center">
              <p className="py-10 font-bold text-center max-w-2xl">
                Through our expertise in delivering intelligent app solutions,
                we empower our customers to gain a competitive edge in their
                respective markets by leveraging new mobile technology.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ...................................... */}
      <div className="w-full h-full flex flex-col items-center">
        <div className="p-4 flex flex-col items-center justify-center lg:flex-row  gap-20 max-w-7xl mt-20">
          <div className="flex flex-col gap-6">
            <img
              src="	https://www.stercodigitex.com/siteassets/images/mobile.svg"
              alt="mobile"
              className="w-48"
            />
            <h1 className="font-bold text-2xl">Mobile UI Design</h1>
            <p>
              The Mobile UI Designs that we create offer an unmatched user
              experience with subtlety, beauty, and intuitiveness. Simplicity
              meets minimalism in all our UI designs. The interfaces we create
              offer a fresh and natural feel with zero distraction. Keeping the
              clutter to a minimum to obtain the simplest form of user
              experience forms the basis of our design philosophy.
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <img
              src="	https://www.stercodigitex.com/siteassets/images/native.svg"
              alt="mobile"
              className="w-48"
            />
            <h1 className="font-bold text-2xl">Native App Development</h1>
            <p>
              Amkamtech is a best-in-class native iPhone and Android Mobile App
              development company in India. In a short span of time, Amkamtech
              has launched a wide range of B2B and B2C Apps catering to users
              across the globe. The team carries significant expertise in design
              and development of native apps, using iOS and Android SDK.
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <img
              src="	https://www.stercodigitex.com/siteassets/images/hybird.svg"
              alt="mobile"
              className="w-48"
            />
            <h1 className="font-bold text-2xl">Hybrid App Development</h1>
            <p>
              Devising technology in favor of our clients’ business proposition,
              Sterco Digitex holds expertise in developing Hybrid Mobile Apps
              that combine best of both worlds- Native and HTML5 functionality.
              Sterco Digitex offers, range of user-friendly, rich-in-interface,
              visually optimized B2B, B2C Apps across major platforms- iOS,
              Android,.
            </p>
          </div>
        </div>
      </div>
      <div id="footer" className="mt-20">
        <Footer />
      </div>
    </>
  );
};

export default MobileAppsPage;
