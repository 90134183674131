import React, { useEffect, useState } from "react";
import Navbar from "../navBar/index";
import "./style.css";
import Vehicle from "../../assets/images/vehicle.svg";
import Exp from "../../assets/images/experince.svg";
// import Strategy from "../../assets/images/startegy.svg"
import Discovery from "../../assets/images/discovery.svg";
import Strategy from "../../assets/images/startegy.svg";
import Creativity from "../../assets/images/creativity.svg";
import Footer from "../Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DropupSecond from "../../assets/images/dropupsecond.svg";
import img1 from "../../assets/images/Fashionicon.png";
import img2 from "../../assets/images/Fmcgicon.png";
import img3 from "../../assets/images/Fintechicon.png";
import img4 from "../../assets/images/Martechicon.png";
import img5 from "../../assets/images/Consumericon.png";
import img6 from "../../assets/images/Educationicon.png";
import img7 from "../../assets/images/Retailicon.png";
import img8 from "../../assets/images/Web3icon.png";
import img9 from "../../assets/images/Automobileicon.png";

function MarketingPage() {
  const industrycards = [
    { name: "Retail" },
    { name: "FMCG" },
    { name: "Education/EdTech" },
    { name: "FinTech" },
    { name: "Automobiles" },
    { name: "Consumer electronics" },
    { name: "Web3" },
    { name: "Martech" },
    { name: "Fashion" },
  ];

  const geographycards = [
    { name: "India" },
    { name: "USA" },
    { name: "UK" },
    { name: "Ireland" },
    { name: "UAE" },
    { name: "Singapore" },
    { name: "Australia" },
    { name: "France" },
    { name: "Oman" },
  ];

  const partnership = [
    { name: "GOOGLE" },
    { name: "LINKEDIN" },
    { name: "BING" },
    { name: "SNAPCHAT" },
    { name: "YOUTUBE" },
    { name: "META" },
    { name: "AMAZON" },
    { name: "QUORA" },
    { name: "MICROSOFT" },
    { name: "TWITTER" },
    { name: "DISNEY" },
    { name: "APPLE" },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const servicecontent = [
    {
      heading: "Consultation",
      content:
        "Developing and implementing a digital roadmap, identifying target audiences, and setting goals and KPIs.",
    },
    {
      heading: "Branding",
      content:
        "Developing brand personas, identity design, creating tone-of-voice, creation of a content strategy, audits for adherence to brand guides.",
    },
    {
      heading: "Video",
      content:
        "Short and long form end-to-end video creation (ideation to publication).",
    },
    {
      heading: "Content Engine",
      content:
        "Creating quality written & image content for all active platforms.",
    },
    {
      heading: "Technology Automation",
      content:
        "Automation transformation for reporting, routine push messaging,performance metrics and automated alerts.",
    },
    {
      heading: "SEO",
      content:
        "Increasing asset visibility as per best practices for each platform.",
    },
    {
      heading: "Reputation Management",
      content:
        "Monitoring and managing brand reputation through counters to support for, customer feedback, media coverage and social media chatter.",
    },
    {
      heading: "Analytics",
      content:
        "Regular and periodised reporting on key metrics, including explanations and insights for changes therein.",
    },
    {
      heading: "Social Media & Influencer Management",
      content:
        "Choosing the right influencers for the product and brand. Management of said influencers for project durations.",
    },
  ];

  const [cursorX, setCursorX] = useState(-100);
  const [cursorY, setCursorY] = useState(-100);

  useEffect(() => {
    const cursorRounded = document.querySelector(".rounded");
    const handleMouseMove = (e) => {
      setCursorX(e.clientX);
      setCursorY(e.clientY);
      cursorRounded.style.transition = `transform 0.12s`;
    };

    const handleScroll = () => {
      setCursorY((prevY) => prevY);
    };

    const handleResize = () => {
      setCursorX(-100);
      setCursorY(-100);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // const cursorRounded = document.querySelector('.rounded');
  // const handleScroll = (e)=>{
  //   console.log(cursorY);
  //   console.log(window.pageYOffset);
  //   setCursorY(()=>{return cursorY+window.pageYOffset})
  // }
  // const handleMouseMove = (e) => {
  //   setCursorX(e.clientX);
  //   setCursorY(e.clientY);

  //   //cursorRounded.style.transform = `translate3d(${cursorX-13}px, ${(cursorY)-13}px, 0)`;
  //   cursorRounded.style.transition= `transform 0.09s`
  // };
  // //window.addEventListener("mousemove", handleMouseMove);
  // document.addEventListener("scroll",handleScroll);

  // useEffect(() => {
  //   const cursorRounded = document.querySelector('.rounded');

  //   window.addEventListener("scroll", handleMouseMove);
  // //  document.addEventListener("scroll", handleScroll);

  // }, [cursorX,cursorY]);

  // useEffect(()=>{
  //   const handleScroll=()=>{

  //   };

  // },[])

  return (
    <div className="body marketing_section">
      <div
        class="cursor rounded"
        style={{
          left: `${cursorX - 13}px`,
          top: `${cursorY - 13}px`,
          transform: "0.1s",
        }}
      ></div>
      <Navbar />
      <div className="marketing_page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 style={{ fontFamily: "tabloid" }}>
                We Sell <span>Yellow,</span>
                <br />
                When It Says <label>Blue</label>
              </h2>
            </div>
            <div
              className="col-lg-6 col-md-6 mt-2"
              style={{ fontFamily: "EB Garamond" }}
            >
              <p>
                Picking WHAT to say, is half the battle won. The other half, is
                saying it right Let us help you decide what to say, how to to
                say it and who to say it to.
              </p>
              <button className="explore_btn d-flex align-items-center">
                <a href="#ourServices">
                  <img src={DropupSecond} alt="dropup" />
                </a>
              </button>
            </div>
            <div className="col-lg-6 col-md-6 mt-2">
              <div className="vehicle_conatiner py-lg-0 py-md-0 py-sm-4 py-4">
                <img src={Vehicle} alt="vehicle" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sale_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                style={{ fontFamily: "tabloid" }}
                className="text-center bestline_text"
              >
                How best can you sell your blue coloured{" "}
                <span className="yellow_color_display">yellows</span> and your
                yellows coloured{" "}
                <span className="blue_color_display">blues?</span>
                <div className="bestsell_line"></div>
              </h2>
              <p
                style={{ fontFamily: "Libre Baskerville" }}
                className="text-center mt-lg-5 mt-mt-md-5 mt-sm-3 mt-3"
              >
                We’ll let you know.
                <span className="d-block">
                  But we’d rather do it the way it’s worked for us
                  traditionally.
                </span>
                In person. And over a cup of coffee.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="OurServices_section" id="ourServices">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <h2
                style={{ fontFamily: "tabloid" }}
                className="title_ecom service_heading"
              >
                Our Services<div className="our_service_line"></div>
              </h2>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              {" "}
              <p className="pt-lg-0 pt-md-0 pt-sm-3 pt-3"></p>
            </div>
            <div className="col-lg-12">
              <div className="accordian_section pt-lg-5 mt-lg-5 pt-md-5 mt-md-5 pt-sm-5 mt-sm-4 pt-5 mt-4">
                {/* <Accordion defaultActiveKey="0"> */}
                {servicecontent.map((item, idx) => {
                  return (
                    <div className="accordion-item-start" eventKey={idx}>
                      {/* <Accordion.Header> */}
                      <div className="row w-100 align-items-center">
                        <div className="col-lg-5">
                          <h2 style={{ fontFamily: "tabloid" }}>
                            {item.heading}
                          </h2>
                        </div>
                        <div
                          className="col-lg-7"
                          style={{ fontFamily: "EB Garamond" }}
                        >
                          <p>{item.content}</p>
                        </div>
                      </div>
                      {/* </Accordion.Header> */}
                      {/* <Accordion.Body>{item.content}</Accordion.Body> */}
                    </div>
                  );
                })}
                {/* </Accordion> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cut_through_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <h2 className="about_heading" style={{ fontFamily: "tabloid" }}>
                CREATIVE CAMPAIGNS THAT{" "}
                <span className="d-block cut_text"> CUT THROUGH</span>
                <div className="about_line"></div>
              </h2>
            </div>
            <div
              className="col-lg-7"
              style={{ fontFamily: "Libre Baskerville" }}
            >
              <p>
                We work with you to help them develop and execute effective
                marketing strategies{" "}
              </p>
              <p>
                We conduct market research, analyse competitors, target
                audience, develop marketing plans and support their
                implementation. We work with you to achieve established business
                goals through more effective and efficient use of your marketing
                resources.
              </p>
              <p>
                We are agnostic to industry, market size and duration of
                operations. However, the most of our team born as citizens of
                the internet, we gravitate towards projects that require
                optimisation in internet marketing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="effactive_digital_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h2 className="most_love_heading">
                {" "}
                <span style={{ fontFamily: "tabloid" }}>
                  WE ARE MOST LOVED FOR
                </span>
                <div className="most_loved_line"></div>
              </h2>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start">
                  <div className="content_effact">
                    <img src={Exp} alt="exp" />
                    <h2 style={{ fontFamily: "tabloid" }} className="title">
                      EXPERTISE
                    </h2>
                    <p style={{ fontFamily: "EB Garamond" }}>
                      We are a group of SMEs with experience across traditional
                      and modern marketing channels. Role specificity helps us
                      provide high efficiency solutions
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="content_effact">
                    <img src={Strategy} alt="startegy" />
                    <h2 style={{ fontFamily: "tabloid" }} className="title">
                      HONESTY
                    </h2>
                    <p style={{ fontFamily: "EB Garamond" }}>
                      In smoke and mirrors of multiple metrics, vague ROMS
                      calculations, fast paced technological changes, we offer
                      clarity basis metrics such as revenue & profitability
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start ">
                  <div className="content_effact">
                    <img src={Discovery} alt="discovery" />
                    <h2 style={{ fontFamily: "tabloid" }} className="title">
                      HUMILITY
                    </h2>
                    <p style={{ fontFamily: "EB Garamond" }}>
                      We don’t know your business better than you, and we don’t
                      pretend to do so. We are here to help, not establish
                      supremacy
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="content_effact">
                    <img src={Creativity} alt="creativity" />
                    <h2 style={{ fontFamily: "tabloid" }} className="title">
                      EMPATHY
                    </h2>
                    <p style={{ fontFamily: "EB Garamond" }}>
                      Humans are multi-faceted, and there is no singular way of
                      asking or answering a question. We appreciate human
                      diversity and cherish flexibility in interpersonal
                      transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clients_section_conatier">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="clients_section">
                <h2
                  className="clients_section_heading"
                  style={{ fontFamily: "tabloid" }}
                >
                  OUR AWESOME <span>CLIENTS</span>
                </h2>
                <div className="clients_line"></div>
                {/* <ul className="footer_menu d-flex">
                  <li className="pe-lg-5 pe-md-5 pe-sm-3 pe-3"><button onClick={()=>{
                    SetIndustry(true);
                    SetGeography(false);
                    SetPartenship(false);
                  }}>INDUSTRY</button></li>
                  <li className="pe-lg-5 pe-md-5 pe-sm-3 pe-3"><button onClick={()=>{
                    SetIndustry(false);
                    SetGeography(false);
                    SetPartenship(true);
                  }}>PARTNERSHIPS</button></li>
                  <li className="pe-lg-5 pe-md-5 pe-sm-3 pe-3"><button onClick={()=>{
                    SetIndustry(false);
                    SetGeography(true);
                    SetPartenship(false);
                  }}>GEOGRAPHY</button></li>
                </ul> */}
                <div className="row">
                  <Tabs
                    defaultActiveKey="Industry"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="Industry"
                      title="INDUSTRY"
                      style={{ fontFamily: "Roboto Condensed" }}
                    >
                      <div
                        className="row"
                        style={{ fontFamily: "EB Garamond" }}
                      >
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="zeroicon">
                              {industrycards[0].name}
                              <img src={img1} />
                            </li>
                            <li className="oneicon">
                              {industrycards[1].name}
                              <img src={img2} />
                            </li>
                            <li className="twoicon">
                              {industrycards[2].name}
                              <img src={img3} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="fouricon">
                              {industrycards[3].name}
                              <img src={img4} />
                            </li>
                            <li className="fiveicon">
                              {industrycards[4].name}
                              <img src={img5} />
                            </li>
                            <li className="sixicon">
                              {industrycards[5].name}
                              <img src={img6} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="sevenicon">
                              {industrycards[6].name}
                              <img src={img7} />
                            </li>
                            <li className="eighticon">
                              {industrycards[7].name}
                              <img src={img8} />
                            </li>
                            <li className="nineicon">
                              {industrycards[8].name}
                              <img src={img9} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="geography" title="GEOGRAPHY">
                      <div
                        className="row"
                        style={{ fontFamily: "EB Garamond" }}
                      >
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="zeroicon">
                              {geographycards[0].name}
                              <img src={img1} />
                            </li>
                            <li className="oneicon">
                              {geographycards[1].name}
                              <img src={img2} />
                            </li>
                            <li className="twoicon">
                              {geographycards[2].name}
                              <img src={img3} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="fouricon">
                              {geographycards[3].name}
                              <img src={img4} />
                            </li>
                            <li className="fiveicon">
                              {geographycards[4].name}
                              <img src={img5} />
                            </li>
                            <li className="sixicon">
                              {geographycards[5].name}
                              <img src={img6} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="sevenicon">
                              {geographycards[6].name}
                              <img src={img7} />
                            </li>
                            <li className="eighticon">
                              {geographycards[7].name}
                              <img src={img8} />
                            </li>
                            <li className="nineicon">
                              {geographycards[8].name}
                              <img src={img9} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Partnership"
                      title="PARTNERSHIP"
                      style={{ fontFamily: "EB Garamond" }}
                    >
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="zeroicon">
                              {partnership[0].name}
                              <img src={img1} />
                            </li>
                            <li className="oneicon">
                              {partnership[1].name}
                              <img src={img2} />
                            </li>
                            <li className="twoicon">
                              {partnership[2].name}
                              <img src={img3} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="fouricon">
                              {partnership[3].name}
                              <img src={img4} />
                            </li>
                            <li className="fiveicon">
                              {partnership[4].name}
                              <img src={img5} />
                            </li>
                            <li className="sixicon">
                              {partnership[5].name}
                              <img src={img6} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="sevenicon">
                              {partnership[6].name}
                              <img src={img7} />
                            </li>
                            <li className="eighticon">
                              {partnership[7].name}
                              <img src={img8} />
                            </li>
                            <li className="nineicon">
                              {partnership[8].name}
                              <img src={img9} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="image_section" id="footer"></div>
      <Footer />
    </div>
  );
}

export default MarketingPage;
