import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Navbar from "../navBar/index";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Portfolio from "../../assets/images/portfolio.svg";
// import Dropup from "../../assets/images/dropup.svg";
import Projects from "../../components/projects/index";
import Timesaving from "../../images/TSaving.svg";
import Easytouse from "../../images/easyToUse.svg";
import OnlineSupport from "../../images/onlineSupport.svg";
import Veritable from "../../images/veritable.svg";
import Footer from "../Footer";
import punctuality from "../../assets/images/punctualitity.svg";
import Collaborativity from "../../assets/images/collabarativity.svg";
import Creativity from "../../assets/images/creativity.svg";
import Curosity from "../../assets/images/curiosity.svg";
import Honest from "../../assets/images/honesty.svg";
import Adaptability from "../../assets/images/adaptibility.svg";
import Objectivity from "../../assets/images/objectivity.svg";
import Positivity from "../../assets/images/positivity.svg";
import professionalism from "../../assets/images/professionalism.svg";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function HomePage() {
  const [visited, setVisited] = useState(false);

  let location = useLocation();
  if (location.state != null && !visited) {
    const { to } = location.state;
    console.log(to);
    const yOffset = -10;
    const element = document.getElementById(to);
    console.log(element);
    if (element != null) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      location.state = null;
      setVisited(true);
    }
  }

  window.onload = function () {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const firstSection = document.querySelector(".your_brand_section");

    firstSection.addEventListener("animationend", function () {
      window.scroll({
        top: firstSection.offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    });
  }, []);

  const [cursorX, setCursorX] = useState(-100);
  const [cursorY, setCursorY] = useState(-100);

  useEffect(() => {
    const cursorRounded = document.querySelector(".rounded");
    const handleMouseMove = (e) => {
      setCursorX(e.clientX);
      setCursorY(e.clientY);
      cursorRounded.style.transition = `transform 0.12s`;
    };

    const handleScroll = () => {
      setCursorY((prevY) => prevY);
    };

    const handleResize = () => {
      setCursorX(-100);
      setCursorY(-100);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [cursorX, cursorY]);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var slidercard = [
    {
      heading: "Curiosity",
      img: Curosity,
      content:
        "We are driven by our innate desire to learn new things and play with them to allow for novel solutions.",
    },
    {
      heading: "Creativity",
      img: Creativity,
      content:
        "We love doing new things, and finding new ways of doing old things better. We adore innovation.",
    },
    {
      heading: "Honesty",
      img: Honest,
      content:
        "Transparency in what we do, how we do, respect for truthfulness and integrity are our guiding light.",
    },
    {
      heading: "Positivity",
      img: Positivity,
      content:
        "Everything could be better, but it could also be worse. It’s always been better to look at the brighter side of life.",
    },
    {
      heading: "Objectivity",
      img: Objectivity,
      content:
        "Everything is measurable on a certain scale, and numbers are sacrosanct. That’s what decides performance.",
    },
    {
      heading: "Adaptability",
      img: Adaptability,
      content:
        "Ties in with curiosity. From new technology, to new industries and new ways of working. We’re flexible.",
    },
    {
      heading: "Professionalism",
      img: professionalism,
      content:
        "We deliver what we signed up for, in the form of what we promised, and in time that it was promised.",
    },
    {
      heading: "Collaborative",
      img: Collaborativity,
      content:
        "We don’t operate in silos. Think of us as embedded partners. It’s not a dance-off with us. It’s tango.",
    },
    {
      heading: "Punctuality",
      img: punctuality,
      content:
        "Time is the most critical currency there is. We respect yours and we expect others to respect ours.",
    },
  ];

  var processcontent = [
    {
      heading: "Assessment",
      content:
        "We put together information about your current business metrics, needs, and goals.",
    },
    {
      heading: "Problem definition",
      content:
        "We sit with you and define, as best as possible, what it is that you’d want us to resolve and what the final outcome would look like.",
    },
    {
      heading: "Solution development",
      content:
        "We develop a customised solution that we think will resolve what we’d planned to, and run it through you.",
    },
    {
      heading: "Implementation",
      content:
        "Once we have a go-ahead on the proposed solution, we put it into action.",
    },
    {
      heading: "Monitoring",
      content:
        "We keep an eye on how the the implementation is going basis decided timeline and metrics.",
    },
    {
      heading: "Completion",
      content:
        "We present the results of the implementation and then exit post a comprehensive information handover or stay on in a custodial / operator role, if required.",
    },
  ];

  return (
    <div className="body home_page_section">
      <Navbar />
      <div
        class="cursor rounded"
        style={{
          left: `${cursorX - 13}px`,
          top: `${cursorY - 13}px`,
          transform: "0.1s",
        }}
      ></div>
      <div className="your_brand_section">
        <div className="container">
          <div className="row justify-content-center h-screen">
            <div className="">
              <p
                className="brand_text mt-[25vh] md:mt-[30vh] w-full text-center"
                style={{ fontFamily: "Manrope" }}
              >
                Have you been trying to:{" "}
                <span className="d-block ">
                  Increase revenue, Build profitability & Sustain growth
                </span>
                <span className="yellow_text  ">Then it’s time to</span>
              </p>
              <h2
                className="brandtitle w-full text-center "
                style={{ fontFamily: "EB Garamond" }}
              >
                Align with
                <span className="d-block yellow_text w-full text-center ">
                  Amkamtech
                </span>
              </h2>
              <div className="text-white mt-[7vh] md:p-10 md:mt-0 text-center">
                <Link to="/webdesign">
                  <button className="xl:mr-[2vw] mr-[4vw] hover:underline hover:text-yellow">
                    Web Design & Development
                  </button>
                </Link>
                <Link to="/mobileapps">
                  <button className="xl:mr-[2vw] mr-[4vw] hover:underline hover:text-yellow">
                    {" "}
                    Mobile Apps
                  </button>
                </Link>
                <Link to="/marketing">
                  <button className="xl:mr-[2vw] mr-[4vw] mt-[3vh] hover:underline hover:text-yellow">
                    Marketing
                  </button>
                </Link>
                <Link to="/ecommercepage">
                  <button className=" hover:underline hover:text-yellow">
                    Ecommerce
                  </button>
                </Link>
              </div>

              {/* <img src={Dropup} alt="dropup" /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="h-[50vh] mt-[20vh] z-[2] relative max-w-7xl mx-auto">
        <h1 className="pt-[23px] font-extrabold text-center w-10/12 md:w-11/12 mx-auto md:text-[22px] heading2">
          Your investment in Digital space today can be your acclerate your
          financial growth tomorrow
        </h1>

        <div className="flex   flex-col md:flex-row mx-w-7xl mx-auto  gap-x-10 md:gap-x-16 mt-[112px] mb-[110px] gap-y-10 items-center ">
          <div className="flex items-center    gap-x-10  md:gap-x-16 md:w-6/12 md:justify-end">
            <div className=" ">
              <img src={Veritable} alt="as" className="min-w-[125px]" />
              <p className="text-center font-medium ">Veritable Trust</p>
            </div>

            <div className=" ">
              <img
                src={OnlineSupport}
                alt="as"
                className="text-center min-w-[125px]"
              />
              <p className="text-center font-medium">Online Support</p>
            </div>
          </div>
          <div className="flex gap-x-10 md:gap-x-16  md:w-6/12 items-center">
            <div className="">
              <img src={Timesaving} alt="as" className=" min-w-[125px]" />
              <p className="text-center font-medium">Time Saving</p>
            </div>

            <div className="">
              <img src={Easytouse} alt="as" className="min-w-[125px]" />
              <p className="text-center font-medium">Easy To Use</p>
            </div>
          </div>
        </div>
      </div>

      <div id="Projects">
        <Projects />
      </div>
      <div className="slider_section">
        <div className="container">
          <div className="row">
            <div className="col-12 project_col" id="Values">
              <h2
                style={{ fontFamily: "tabloid" }}
                className="values_text position-relative"
              >
                OUR VALUES <div className="values_line position-absolute"></div>
              </h2>
            </div>
            <Slider {...settings} className="mt-5">
              {slidercard.map((item) => {
                return (
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="slider_content">
                      <img className="m-auto" src={item.img} alt="portfolio" />
                      <h2 style={{ fontFamily: "tabloid" }}>{item.heading}</h2>
                      <p style={{ fontFamily: "EB Garamond" }}>
                        {item.content}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="OurServices_section our_process_section">
        <div className="container">
          <div className="row project_col" id="Process">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h2 className="title_ecom " style={{ fontFamily: "tabloid" }}>
                PROCESS
              </h2>
              <div className="process_line"></div>
            </div>

            <div className="col-lg-12">
              <div className="accordian_section pt-lg-5 mt-lg-5 pt-md-5 mt-md-5 pt-sm-5 mt-sm-4 pt-5 mt-4">
                {/* <Accordion defaultActiveKey="0"> */}
                {processcontent.map((item, idx) => {
                  return (
                    <div className="accordion-item-start" eventKey={idx}>
                      {/* <Accordion.Header> */}
                      <div className="row w-100 align-items-center">
                        <div className="col-lg-5">
                          <h2 style={{ fontFamily: "tabloid" }}>
                            {item.heading}
                          </h2>
                        </div>
                        <div className="col-lg-7">
                          <p style={{ fontFamily: "EB Garamond" }}>
                            {item.content}
                          </p>
                        </div>
                      </div>
                      {/* </Accordion.Header> */}
                      {/* <Accordion.Body>
                         {item.content}
                        </Accordion.Body> */}
                    </div>
                  );
                })}
                {/* </Accordion> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="Footer">
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
