import React from "react";
import Navbar from "../components/navBar/index";
import Footer from "../components/Footer";
import Web from "../images/web.png";
import { Link } from "react-router-dom";

const WebDesignPage = () => {
  return (
    <>
      <div className="bg-black   w-full h-full flex flex-col ">
        <div >
          <Navbar />
        </div>
        <div className="mt-36 text-white px-2">
          <div className="flex flex-col gap-4 items-center lg:items-start lg:px-32">
            <h1 className="text-lg  lg:text-4xl font-bold">
              Web Design & Development
            </h1>
            <div className="flex">
              <div className="w-24 h-1 bg-blue"></div>
              <div className="w-24 h-1 bg-white"></div>
            </div>
            <div className="w-full text-sm font-semibold flex flex-col items-center lg:items-start lg:text-5xl mt-10 ">
              <h1>Create and control your web identity with</h1>
              <p>an enviable website</p>
            </div>
            <p className="">
              Your website is the first impression of your company and speaks
              volumes about your work, not just in terms of the information that
              it carries but more importantly through the experience it gives to
              website visitors. While getting a website designed & developed,
              you need a partner with an industry specific approach and creative
              zeal.
            </p>
            <div className="flex justify-center w-full  gap-4 py-10">
              <img
                src={Web}
                alt="wen design & development"
                className="w-[200px] lg:w-[300px]"
              />
              <p className="hidden lg:flex w-[300px] text-sm p-4">
                AmKamtech, a leading Web Design and Development Company, is
                where your search will end and you will walk out with a modern
                website that gives you a leading edge in the industry.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" px-2 lg:px-36 flex flex-col gap-10 py-10 justify-center items-center">
        <p className="lg:hidden">
          AmKamtech, a leading Web Design and Development Company, is where your
          search will end and you will walk out with a modern website that gives
          you a leading edge in the industry.
        </p>
        <div className="flex flex-col  gap-20 items-center lg:flex-row lg:gap-36">
          <div className="flex gap-6  lg:gap-36">
            <div className="flex flex-col gap-6">
              <h1>
                Innovative web solutions
                <br /> since the last
              </h1>
              <div>
                <h2 className="font-bold text-6xl">23+</h2>
                <h2 className="font-bold text-3xl">years</h2>
              </div>
              <div className="w-full bg-black h-[2px]"></div>
            </div>
            <div className="flex flex-col gap-6">
              <h1>
                Successfully accomplished
                <br /> more than
              </h1>
              <div>
                <h2 className="font-bold text-6xl">1200+</h2>
                <h2 className="font-bold text-3xl">projects</h2>
              </div>
              <div className="w-full bg-black h-[2px]"></div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <h1>Managing more than </h1>
            <div>
              <h2 className="font-bold text-6xl">360+</h2>
              <h2 className="font-bold text-3xl">websites</h2>
            </div>
            <div className="w-full bg-black h-[2px]"></div>
          </div>
        </div>
        <p className="max-w-4xl mt-4">
          At Amkamtech, we believe in developing solutions that serve you with
          enhanced utility for a long period of time. We offer a wide range of
          web design and development services as per the customers'
          requirements.
        </p>
        <div className="mt-14 flex w-full flex-col items-center lg:items-start max-w-4xl gap-10">
          <h1 className="text-2xl font-bold lg:text-4xl">
            Web Design and Development
          </h1>
          <p>
            We design and develop customized websites keeping in mind the
            specific requirements of our customers and establish their unique
            brand identity. Through uniquely designed pages, carefully selected
            images, compelling content on every page and intuitive navigation,
            we provide an immersive experience for users.
          </p>
          <Link to="/">
            <button className="bg-blue p-2 rounded-full  text-white  ">
              View Profile
            </button>
          </Link>
        </div>
        <div className="mt-14 flex w-full flex-col items-center lg:items-start max-w-4xl gap-10">
          <h1 className="text-2xl font-bold lg:text-4xl">
            Ecommerce Development
          </h1>
          <p>
            We provide the most appropriate ecommerce solutions for your
            business. From design and selection of right development platform
            i.e. Custom, Magento, WooComerce, or Shopify to other services, we
            are the one-stop-shop for all your ecommerce requirements.
          </p>
          <Link to="/ecommercepage">
            <button className="bg-blue p-2 rounded-full  text-white  ">
              View Profile
            </button>
          </Link>
        </div>
        <div className="flex flex-col lg:flex-row gap-6 mt-20 max-w-6xl">
          <div className="flex flex-col gap-6 items-center lg:items-start">
            <h1 className="font-bold text-3xl lg:text-4xl ">Technology</h1>
            <p className="text-xl">
              Using advanced technology to bring you high end solutions in new
              media, web and e-learning
            </p>
            <p>
              At Amkamtech we believe in walking at pace with the growing
              technology and that is how we deliver results beyond expectations.
              We make use of the latest software and technology platforms to
              develop high end and technically advanced solutions.
            </p>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-2 lg:grid-cols-3">
              <div className=" border-r-2 border-b-2 border-l-2 p-4">
                <img
                  src="https://www.stercodigitex.com/siteassets/images/technicallogo3.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
              <div className="  border-b-2  p-4">
                <img
                  src="	https://www.stercodigitex.com/siteassets/images/technicallogo4.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
              <div className="border-r-2 border-b-2 border-l-2 p-4">
                <img
                  src="https://www.stercodigitex.com/siteassets/images/technicallogo6.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
              <div className=" border-r-2 border-b-2 border-l-2 p-4">
                <img
                  src="https://www.stercodigitex.com/siteassets/images/technicallogo3.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
              <div className="  border-b-2  p-4">
                <img
                  src="	https://www.stercodigitex.com/siteassets/images/technicallogo4.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
              <div className="border-r-2 border-b-2 border-l-2 p-4">
                <img
                  src="https://www.stercodigitex.com/siteassets/images/technicallogo6.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
              <div className=" border-r-2 border-b-2 border-l-2 p-4">
                <img
                  src="https://www.stercodigitex.com/siteassets/images/technicallogo3.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
              <div className="  border-b-2  p-4">
                <img
                  src="	https://www.stercodigitex.com/siteassets/images/technicallogo4.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
              <div className="border-r-2 border-b-2 border-l-2 p-4">
                <img
                  src="https://www.stercodigitex.com/siteassets/images/technicallogo6.webp"
                  alt="tech"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <Footer />
      </div>
    </>
  );
};

export default WebDesignPage;
