import React, { useEffect, useState } from "react";
import ShoppingCart from "../../assets/images/shoppingCart.svg";
import EcomConsultant from "../../assets/images/ecomconsultant.svg";
import BorderLine from "../../assets/images/border-line.svg";
import Footer from "../Footer";
import "./style.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Navbar from "../navBar/index";
import Dropup from "../../assets/images/dropup.svg";
import img1 from "../../assets/images/Fashionicon.png";
import img2 from "../../assets/images/Fmcgicon.png";
import img3 from "../../assets/images/Fintechicon.png";
import img4 from "../../assets/images/Martechicon.png";
import img5 from "../../assets/images/Consumericon.png";
import img6 from "../../assets/images/Educationicon.png";
import img7 from "../../assets/images/Retailicon.png";
import img8 from "../../assets/images/Web3icon.png";
import img9 from "../../assets/images/Automobileicon.png";

function EcommercePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [Industry, SetIndustry] = useState(true);
  const [Partnership, SetPartenship] = useState(false);
  const [Geography, SetGeography] = useState(false);

  var flag = true;
  window.addEventListener("scroll", () => {
    if (window.scrollY > 3550 && flag) {
      let valueDisplays = document.querySelectorAll(".num");
      let interval = 4000;
      valueDisplays.forEach((valueDisplay) => {
        let startValue = 0;
        let endValue = parseInt(valueDisplay.getAttribute("data-val"));
        let duration = Math.floor(interval / endValue);
        let counter = setInterval(function () {
          startValue += 1;
          valueDisplay.textContent = startValue;
          if (startValue == endValue) {
            clearInterval(counter);
          }
        }, duration);
      });
      flag = false;
    }
  });

  var servicescontent = [
    {
      heading: "COMMUNITY DEVELOPMENT",
      content:
        "This we believe is at the core of business success. We help establish and run flourishing brand loving communities.",
    },
    {
      heading: "E-COMMERCE STRATEGY & PLANNING",
      content:
        "Developing & implementing a roadmap for your company's online presence. identifying target audiences. Setting goals and KPIs.",
    },
    {
      heading: "ONLINE MARKETING",
      content:
        "Promoting products or services online through multiple channels, including (but not limited to) email marketing, social media, and paid search.",
    },
    {
      heading: "PRODUCT LISTING & MANAGEMENT",
      content:
        "Creating and managing product listings, including product descriptions, images, and pricing information. (Both owned and third-party platforms).",
    },
    {
      heading: "WEBSITE DESIGN & DEVELOPMENT",
      content:
        "Creating modern websites (with definitive brand elements), integrating payment gateways, and ensuring mobile responsiveness.",
    },
    {
      heading: "USER EXPERIENCE (UX) OPTIMIZATION",
      content:
        "Optimising website navigation, increasing conversion rates, and making the website more accessible to users.",
    },
    {
      heading: "PAYMENT PROCESSING AND SECURITY",
      content:
        "Setting up secure payment gateways, ensuring compliance with payment processing regulations, and providing protection against fraud and security breaches.",
    },
    {
      heading: "DATA DRIVEN CUSTOMER RELATIONSHIP MANAGEMENT (CRM)",
      content:
        "Analysing customer data and provide interventions to drive sales and improve customer satisfaction.",
    },
    {
      heading: "SEARCH ENGINE OPTIMIZATION (SEO)",
      content:
        "Optimising websites to rank higher in search engine results, driving organic traffic to the site, and improving visibility.",
    },
    {
      heading: "DATA ANALYSIS AND REPORTING",
      content:
        "Analysing website and customer data, tracking key metrics, and generating reports & insights.",
    },
    {
      heading: "TECHNICAL SUPPORT AND MAINTENANCE",
      content:
        "Providing ongoing support and maintenance for the company's e-commerce platform, fixing technical issues, and ensuring the websites stays upto-date with the latest technologies.",
    },
  ];

  const industrycards = [
    { name: "Retail" },
    { name: "FMCG" },
    { name: "Education/EdTech" },
    { name: "FinTech" },
    { name: "Automobiles" },
    { name: "Consumer electronics" },
    { name: "Web3" },
    { name: "Martech" },
    { name: "Fashion" },
  ];

  const geographycards = [
    { name: "India" },
    { name: "USA" },
    { name: "UK" },
    { name: "Ireland" },
    { name: "UAE" },
    { name: "Singapore" },
    { name: "Australia" },
    { name: "France" },
    { name: "Oman" },
  ];

  const partnership = [
    { name: "GOOGLE" },
    { name: "LINKEDIN" },
    { name: "BING" },
    { name: "SNAPCHAT" },
    { name: "YOUTUBE" },
    { name: "META" },
    { name: "AMAZON" },
    { name: "QUORA" },
    { name: "MICROSOFT" },
    { name: "TWITTER" },
    { name: "DISNEY" },
    { name: "APPLE" },
  ];

  const [cursorX, setCursorX] = useState(-100);
  const [cursorY, setCursorY] = useState(-100);

  useEffect(() => {
    const cursorRounded = document.querySelector(".rounded");
    const handleMouseMove = (e) => {
      setCursorX(e.clientX);
      setCursorY(e.clientY);
      cursorRounded.style.transition = `transform 0.12s`;
    };

    const handleScroll = () => {
      setCursorY((prevY) => prevY);
    };

    const handleResize = () => {
      setCursorX(-100);
      setCursorY(-100);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [cursorX, cursorY]);

  return (
    <div className=" body ecom_wrapper">
      <div
        class="cursor rounded"
        style={{
          left: `${cursorX - 13}px`,
          top: `${cursorY - 13}px`,
          transform: "0.1s",
        }}
      ></div>
      <div
        className="ecommerce_section d-flex align-items-center"
        style={{ backgroundImage: `url(${ShoppingCart})` }}
      >
        <Navbar />
        <div className="container">
          <div className="row h-100 align-items-center w-100">
            <div className="content pb-5 mb-5">
              <h2 style={{ fontFamily: "tabloid" }}>
                No Shopping
                <br />
                Cart Deserves
                <br />
                To Be Empty
              </h2>
              <p style={{ fontFamily: "EB Garamond" }}>
                Summer is here. Improve & optimise unit profitability
                <br /> and customer value for your ecommerce business.
              </p>
              <a href="#ecom">
                <button className="explore_btn d-flex align-items-center">
                  <img src={Dropup} alt="dropup" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        id="ecom"
        className="ecom_consultant_section d-flex align-items-center"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                className="title_ecom title_heading"
                style={{ fontFamily: "tabloid" }}
              >
                We’re an <span>e-commerce</span>
                <br />
                consultancy
              </h2>
              <div className="title_linr"></div>
            </div>
            <div
              className="col-lg-8 col-md-8 col-sm-10 col-12 ms-auto"
              style={{ fontFamily: "EB Garamond" }}
            >
              <p>That, is the most honest description of us</p>

              <p>
                We’re a team of multi-disciplinary professionals who work with
                clients to increase the efficiency and profitability of their
                e-commerce divisions. Working in a well proven format of
                investigation, intervention, monitoring and reporting, we
                service a variety of KPIs associated with the entire value chain
                of electronic commerce.
              </p>
              <p>
                We service a variety of requirements ranging from resolving low
                open rates for email campaigns to creating and running an online
                store on turnkey basis.
              </p>

              <p>
                We love good coffee and resolving business problems. So please
                feel free to drop us a mail or give us a call. Let’s catch up!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="ecom_consultant_section algo_section d-flex align-items-center"
        style={{ backgroundImage: `url(${EcomConsultant})` }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12 ">
              <h2 className="title_ecom" style={{ fontFamily: "tabloid" }}>
                <span>Amkamtech ALGORITHM</span> FOR <br />
                ELEVATING EXPERIENCE AND BUILDING CONTRIBUTING COMMUNITIES
              </h2>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div
                className="algo_img position-realtive"
                style={{ backgroundImage: `url(${BorderLine})` }}
              >
                <div className="algo_container">
                  <h2>EXPERIENCE x COMMUNITIES</h2>
                  <label>EXPERIENCE = SEARCH + UI/UX + HOOKS</label>
                  <span>Search = Brand + Category + Query</span>
                  <span>UI/UX = Technology + Ease + Content</span>
                  <span>Hooks = Options + Commerce</span>
                  <label>
                    COMMUNITIES = PURPOSE + CONTRIBUTION (BRAND + COMMUNITY) +{" "}
                    <span className="break_content">
                      CONTENT + SEEDING POLICY + COLLABORATIONS & INVESTMENTS
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our_services_section modus_section">
        <div className="container">
          <div className="row">
            <div className="our_service">
              <h2 className="service_heading" style={{ fontFamily: "tabloid" }}>
                OUR SERVICES
                <div className="services_line"></div>
              </h2>
              <div className="service_list">
                {servicescontent.map((item) => {
                  return (
                    <div className="row my-services py-5 g-0">
                      <div
                        style={{ fontFamily: "tabloid" }}
                        className="col-lg-5 title-content"
                      >
                        {item.heading}
                      </div>
                      <div
                        style={{ fontFamily: "EB Garamond" }}
                        className="col-lg-7 text-content"
                      >
                        {item.content}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="countdown_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="peope_line"></div>
              <div className="people_count">
                <span>PEOPLE</span>
                <div className="d-flex">
                  <h2 className="num" data-val="25">
                    00
                  </h2>
                  <h2>+</h2>
                </div>
                <p></p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="peope_line"></div>
              <div className="people_count">
                <span>GLOBAL REACH</span>
                <h2 className="num" data-val="32">
                  00
                </h2>
                <p></p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="peope_line"></div>
              <div className="people_count">
                <span>YEARS</span>
                <h2 className="num" data-val="16">
                  00
                </h2>
                <p></p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="peope_line"></div>
              <div className="people_count">
                <span>GROWTH</span>
                <div className="d-flex">
                  <h2 className="num" data-val="34">
                    00
                  </h2>
                  <h2>+</h2>
                </div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clients_section_conatier">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="clients_section">
                <h2
                  className="clients_section_heading"
                  style={{ fontFamily: "tabloid" }}
                >
                  OUR AWESOME <span>CLIENTS</span>
                </h2>
                <div className="clients_line"></div>
                {/* <ul className="footer_menu d-flex">
                  <li className="pe-lg-5 pe-md-5 pe-sm-3 pe-3"><button onClick={()=>{
                    SetIndustry(true);
                    SetGeography(false);
                    SetPartenship(false);
                  }}>INDUSTRY</button></li>
                  <li className="pe-lg-5 pe-md-5 pe-sm-3 pe-3"><button onClick={()=>{
                    SetIndustry(false);
                    SetGeography(false);
                    SetPartenship(true);
                  }}>PARTNERSHIPS</button></li>
                  <li className="pe-lg-5 pe-md-5 pe-sm-3 pe-3"><button onClick={()=>{
                    SetIndustry(false);
                    SetGeography(true);
                    SetPartenship(false);
                  }}>GEOGRAPHY</button></li>
                </ul> */}
                <div className="row">
                  <Tabs
                    defaultActiveKey="Industry"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                    style={{ fontFamily: "Roboto Condensed" }}
                  >
                    <Tab eventKey="Industry" title="INDUSTRY">
                      <div
                        className="row"
                        style={{ fontFamily: "EB Garamond" }}
                      >
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="zeroicon">
                              {industrycards[0].name}
                              <img src={img1} />
                            </li>
                            <li className="oneicon">
                              {industrycards[1].name}
                              <img src={img2} />
                            </li>
                            <li className="twoicon">
                              {industrycards[2].name}
                              <img src={img3} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="fouricon">
                              {industrycards[3].name}
                              <img src={img4} />
                            </li>
                            <li className="fiveicon">
                              {industrycards[4].name}
                              <img src={img5} />
                            </li>
                            <li className="sixicon">
                              {industrycards[5].name}
                              <img src={img6} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="sevenicon">
                              {industrycards[6].name}
                              <img src={img7} />
                            </li>
                            <li className="eighticon">
                              {industrycards[7].name}
                              <img src={img8} />
                            </li>
                            <li className="nineicon">
                              {industrycards[8].name}
                              <img src={img9} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="geography" title="GEOGRAPHY">
                      <div
                        className="row"
                        style={{ fontFamily: "EB Garamond" }}
                      >
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="zeroicon">
                              {geographycards[0].name}
                              <img src={img1} />
                            </li>
                            <li className="oneicon">
                              {geographycards[1].name}
                              <img src={img2} />
                            </li>
                            <li className="twoicon">
                              {geographycards[2].name}
                              <img src={img3} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="fouricon">
                              {geographycards[3].name}
                              <img src={img4} />
                            </li>
                            <li className="fiveicon">
                              {geographycards[4].name}
                              <img src={img5} />
                            </li>
                            <li className="sixicon">
                              {geographycards[5].name}
                              <img src={img6} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="sevenicon">
                              {geographycards[6].name}
                              <img src={img7} />
                            </li>
                            <li className="eighticon">
                              {geographycards[7].name}
                              <img src={img8} />
                            </li>
                            <li className="nineicon">
                              {geographycards[8].name}
                              <img src={img9} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Partnership" title="PARTNERSHIP">
                      <div
                        className="row"
                        style={{ fontFamily: "EB Garamond" }}
                      >
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="zeroicon">
                              {partnership[0].name}
                              <img src={img1} />
                            </li>
                            <li className="oneicon">
                              {partnership[1].name}
                              <img src={img2} />
                            </li>
                            <li className="twoicon">
                              {partnership[2].name}
                              <img src={img3} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="fouricon">
                              {partnership[3].name}
                              <img src={img4} />
                            </li>
                            <li className="fiveicon">
                              {partnership[4].name}
                              <img src={img5} />
                            </li>
                            <li className="sixicon">
                              {partnership[5].name}
                              <img src={img6} />
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                          <ul className="clients_list ps-1">
                            <li className="sevenicon">
                              {partnership[6].name}
                              <img src={img7} />
                            </li>
                            <li className="eighticon">
                              {partnership[7].name}
                              <img src={img8} />
                            </li>
                            <li className="nineicon">
                              {partnership[8].name}
                              <img src={img9} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}
export default EcommercePage;
