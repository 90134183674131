import React, { useEffect, useState } from "react";
import Model from "../../assets/images/agam.png";
// import Linkdin from "../../assets/images/linkdin.svg";
import Model2 from "../../assets/images/model1.svg";
// import Twitter from "../../assets/images/twitter.svg";
import Accordion from "react-bootstrap/Accordion";
import LoadMore from "../../assets/images/loadMore.svg";
// import ArrowIcon from "../../assets/images/exploreIcon.svg";
// import EcomConsultant from "../../assets/images/ecomconsultant.svg";
import Footer from "../Footer";
import "./style.css";
import Navbar from "../navBar/index";

function Sahilmishra() {
  var flag = true;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 2350 && flag) {
      let ele = Array.from(document.getElementsByClassName("heading_2"));
      ele.forEach((element) => {
        element.classList.add("drop-main");
      });
      flag = false;
    }
  });

  const [cursorX, setCursorX] = useState(-100);
  const [cursorY, setCursorY] = useState(-100);

  useEffect(() => {
    const cursorRounded = document.querySelector(".rounded");
    const handleMouseMove = (e) => {
      setCursorX(e.clientX);
      setCursorY(e.clientY);
      cursorRounded.style.transition = `transform 0.12s`;
    };

    const handleScroll = () => {
      setCursorY((prevY) => prevY);
    };

    const handleResize = () => {
      setCursorX(-100);
      setCursorY(-100);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [cursorX, cursorY]);
  // const observer = new IntersectionObserver(entries => {
  //   entries.forEach(entry => {
  //     const square = entry.target.querySelector('.heading');

  //     if (entry.isIntersecting) {
  //       square.classList.add('drop-main');
  //     return; // if we added the class, exit the function
  //     }

  //     // We're not intersecting, so remove the class!
  //     square.classList.remove('drop-main');
  //   });
  // });

  // observer.observe(document.querySelector('.wrapper_2'));

  return (
    <div className="body agm_wrapper">
      <div
        class="cursor rounded"
        style={{
          left: `${cursorX - 13}px`,
          top: `${cursorY - 13}px`,
          transform: "0.1s",
        }}
      ></div>
      <Navbar />
      <div
        className="agm_section d-flex align-items-lg-center align-items-md-center align-items-sm-end align-items-end"
        style={{ backgroundImage: `url(${Model})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2">
              <ul>
                <li style={{ fontFamily: "tabloid" }} className="mt-5">
                  <a
                    className="linking"
                    href="https://cosedge.com/"
                    target="blank"
                  >
                    Download Resume
                  </a>
                  <div className="download_line"></div>
                </li>
                <li style={{ fontFamily: "tabloid" }} className="mt-5 pt-5">
                  <a className="linking" href="#mystory">
                    Story
                  </a>
                  <div className="story_line"></div>
                </li>
                <li style={{ fontFamily: "tabloid" }} className="mt-4">
                  <a className="linking" href="#footer">
                    Contact
                  </a>
                  <div className="contact_line"></div>
                </li>
                <li style={{ fontFamily: "tabloid" }} className="mt-5 pt-5">
                  <a
                    className="linking d-flex align-items-center"
                    target="blank"
                    href="https://www.linkedin.com/in/"
                  >
                    Linkedin
                    <span className="img_container social-icon_link ms-4">
                      <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </span>
                  </a>
                  <div className="linkdin_line"></div>
                </li>
                <li style={{ fontFamily: "tabloid" }} className="mt-4">
                  <a
                    className="linking d-flex align-items-center"
                    target="blank"
                    href="https://twitter.com/"
                  >
                    Twitter
                    <span className="img_container social-icon_link ms-4">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </span>
                  </a>
                  <div className="twitter_line"></div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 mt-5 pt-5 position-relative">
              <div class="wrapper one pt-5 mt-5">
                {/* <h2 class="heading pt-5"> */}
                {/* <h2 className="heading pt-5">agam <span className="d-block">chaudhary</span></h2> */}
                <div className="d-flex justify-content-end w-100">
                  <div class="drop-main heading">S</div>
                  <div class="drop-main heading">a</div>
                  <div class="drop-main heading">h</div>
                  <div class="drop-main heading">i</div>
                  <div class="drop-main heading">l</div>
                </div>
                <div className="d-flex">
                  <div class="drop-main heading">M</div>
                  <div class="drop-main heading">i</div>
                  <div class="drop-main heading">s</div>
                  <div class="drop-main heading">h</div>
                  <div class="drop-main heading">r</div>
                  <div class="drop-main heading">a</div>
                  </div>
                {/*  <span className="d-block">chaudhary</span> */}
                {/* </h2> */}
                <p
                  style={{ fontFamily: "Roboto Condensed" }}
                  className="pera_text pt-4 d-flex justify-content-end"
                >
                  {" "}
                  I sell tomorrow . Today .
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <a href="#footer">
                <button className="other_projects d-flex align-items-center">
                  Available for new Projects
                  <img src={LoadMore} alt="" className="ml-2 h-5 w-fit" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="introduction_section">
              <p style={{ fontFamily: "EB Garamond" }} className="px-5">
                Most businesses solve for today. But today is here already
                {/* <br></br> */}
                <span class="text-white tracking-wider">
                  . It is a lot smarter to solve for the problems that will
                  continue into tomorrow, and those that tomorrow will bring
                  with it
                </span>
                {/* <br></br> */}. From incorporating cutting edge technology
                and processes that help you sell in an ever evolving landscape,
                to creating business lines that will be in demand in the future
                {/* <br></br>I */}. I am in the business of keeping your
                business future ready.<br></br>
                {/* <br></br> */}
                <br></br>
                <span>I SELL TOMORROW. TODAY.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="services_experience_section">
        <div className="container" id="mystory">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
              <h2 className="services" style={{ fontFamily: "Anton" }}>
                MY Story
              </h2>
              <div className="mystory mt-4"></div>
            </div>
            <div
              style={{ fontFamily: "Libre Baskerville" }}
              className="services_description col-lg-7 col-md-12 col-sm-12 col-12"
            >
              <p className="mb-2 ">
                I am a marketing consultant delivering business results for
                clients across a wide range of industries. With nearly two
                decades of experience in marketing, I have an understanding of
                the latest marketing trends, best practices, and technologies
                being used to achieve superior business outcomes.
              </p>
              <p className="mb-2">
                I have always enjoyed solving problems, attracting me to take on
                complex marketing challenges and turn them into clear,
                actionable strategies.
              </p>
              <p>
                I work in a consultive capability with clients to develop and
                execute customised marketing plans that deliver measurable
                results.
              </p>
              <p>
                I build everything marketing. From digital marketing campaigns
                to branding initiatives, market research projects and everything
                in-between.
              </p>
              <p>
                So if you’re looking for a marketing related solution, or to
                know if you even need something on the lines, drop me a line!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="awards_achievement_section">
        <div className="container">
          <div className="row align-items-baseline">
            <div className="col-lg-12 mb-5">
              <h2 className="exp_link" style={{ fontFamily: "Anton" }}>
                SERVICES & EXPERIENCE
              </h2>
              <div className="service_exp"></div>
            </div>
            {/* </div> */}
            {/* <div className="row"> */}
            <Accordion defaultActiveKey="0">
              <div className="col-lg-12">
                <div
                  style={{ fontFamily: "Libre Baskerville" }}
                  className="row border-style align-items-center g-0 pt-4 pb-5"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <span style={{ fontFamily: "Roboto Condensed" }}>
                          Chief Marketing Maven Nov' 20-ongoing
                        </span>
                      </div>
                      <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                        <p className="awards_para  ps-lg-4 ps-md-0 ps-sm-0 ps-0 my-lg-0 my-md-3 my-sm-3 my-3">
                          {/* <span class="d-block"> */}
                          Nurturing 3 Web3.0 enterprises by integrating
                          communities & experiences through hooks and seeding{" "}
                          {/* </span> */}
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="px-0 d-flex justify-content-start">
                      <div
                        className="col-lg-3 d-lg-block d-md-none d-sm-none d-none text-white"
                        style={{ fontFamily: "Libre Baskerville" }}
                      ></div>
                      <div
                        className="col-lg-8 col-md-12 col-sm-12 col-12 text-white"
                        style={{ fontFamily: "Libre Baskerville" }}
                      >
                        <p className="awards_para  ps-lg-4 ps-md-0 ps-sm-0 ps-0 my-lg-0 my-md-3 my-sm-3 my-3">
                          {/* <span class="d-block"> */} {/* </span> */}
                          Nourishing 2 ecommerce businesses since 2020 by
                          curating stories & framework for Mergers &
                          Acquisitions including Investments
                          <br></br>
                          <br></br>
                          Consulting Brands with their go-to-market tactics
                          through presentations, field research & events
                          <br></br>
                          <br></br>
                          Assisting agencies expand their business inculcating
                          team members, formulating strategies and planning
                          collaborations
                          <br></br>
                          <br></br>
                          Mentoring professionals equipped for Web3.0 through
                          extensive research projects & understanding the
                          dynamics of Metaverse & Cryptocurrency
                          <br></br>
                          <br></br>
                          Monitoring startups to clamber & be profitable through
                          collaborations, innovative tools and customized
                          marketing strategies
                          <br></br>
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <div className="col-lg-2 col-md-12 col-sm-12 col-12 d-flex justify-content-lg-end justify-content-md-start justify-content-sm-start justify-content-start">
                  </Accordion.Item>
                  {/* <div className="col-lg-2 col-md-12 col-sm-12 col-12 d-flex justify-content-lg-end justify-content-md-start justify-content-sm-start justify-content-start">
                  <i class="fa fa-long-arrow-right"></i>
                </div> */}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row align-items-center border-style g-0 pt-4 pb-5">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
                        <span style={{ fontFamily: "Roboto Condensed" }}>
                          Digitalabs/Laqshya Media Group<br></br>
                          Jun' 07-Mar 20 as Chief Marketing Director
                        </span>
                      </div>
                      <div
                        style={{ fontFamily: "Libre Baskerville" }}
                        className="col-lg-8 col-md-12 col-sm-12 col-12 "
                      >
                        <p className="awards_para  ps-lg-4 ps-md-0 ps-sm-0 ps-0 my-lg-0 my-md-3 my-sm-3 my-3">
                          {/* <span class="d-block"> */}
                          Secured category A+ accounts/relationships and
                          Integrate digital & technology with OOH & events{" "}
                          {/* </span> */}
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="px-0 d-flex justify-content-start">
                      <div
                        className="col-lg-3 d-lg-block d-md-none d-sm-none d-none text-white"
                        style={{ fontFamily: "Libre Baskerville" }}
                      ></div>
                      <div
                        className="col-lg-8 col-md-12 col-sm-12 col-12 text-white"
                        style={{ fontFamily: "Libre Baskerville" }}
                      >
                        <p className="awards_para  ps-lg-4 ps-md-0 ps-sm-0 ps-0 my-lg-0 my-md-3 my-sm-3 my-3">
                          {/* <span class="d-block"> */}
                          Steered product development-00H & digital
                          (marketing/technology), led team's integration
                          (digital, OOH & events), & applied innovative &
                          customized marketing strategies aimed at increasing
                          customer acquisition {/* </span> */}
                          <br></br>
                          <br></br>
                          Delivered strategic inputs for launching new products
                          & services, worked closely with the team for correct
                          pricing and product & ensured the implementation of an
                          eicient marketing strategy
                          <br></br>
                          <br></br>
                          Built a robust and accurate revenue pipeline &
                          forecasting & developed a cohesive short, medium, and
                          long-term revenue strategy encompassing marketing
                          sales, product/service, & customer success teams
                          <br></br>
                          <br></br>
                          Created a standardized outreach plan for current and
                          future clients & coordinated its implementation across
                          sales channels, client management, and marketing &
                          communications
                          <br></br>
                          <br></br>
                          Monitored the revenue pipeline through reporting,
                          dashboards, & tracking channels to provide actionable
                          insight
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <div className="col-lg-2 col-md-12 col-sm-12 col-12 d-flex justify-content-lg-end justify-content-md-start justify-content-sm-start justify-content-start">
                  <i class="fa fa-long-arrow-right"></i>
                </div> */}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row align-items-center border-style g-0 pt-4 pb-5">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
                        <span style={{ fontFamily: "Roboto Condensed" }}>
                          Chief Marketing Officer/Director
                        </span>
                      </div>
                      <div
                        style={{ fontFamily: "Libre Baskerville" }}
                        className="col-lg-8 col-md-12 col-sm-12 col-12 "
                      >
                        <p className="awards_para my-lg-0 ps-lg-4 ps-md-0 ps-sm-0 ps-0 my-md-3 my-sm-3 my-3">
                          {/* <span class="d-block"> */}
                          Co-founded Digitalabs & served 140+ business
                          relationships in India & abroad {/* </span> */}
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="px-0 d-flex justify-content-start">
                      <div
                        className="col-lg-3 d-lg-block d-md-none d-sm-none d-none text-white"
                        style={{ fontFamily: "Libre Baskerville" }}
                      ></div>
                      <div
                        className="col-lg-8 col-md-12 col-sm-12 col-12 text-white"
                        style={{ fontFamily: "Libre Baskerville" }}
                      >
                        <p className="awards_para  ps-lg-4 ps-md-0 ps-sm-0 ps-0 my-lg-0 my-md-3 my-sm-3 my-3">
                          {/* <span class="d-block"> */}
                          Formulated campaigns to engage & create interest in
                          the organization's products or services{" "}
                          {/* </span> */}
                          <br></br>
                          <br></br>
                          Led the executive unit to forge growth strategies for
                          pioneering unexplored markets
                          <br></br>
                          <br></br>
                          Directed market research studies & analyzed the
                          results to understand the market and customer tastes
                          better
                          <br></br>
                          <br></br>
                          Determined appropriate media in campaigns & negotiated
                          advertising contracts
                          <br></br>
                          <br></br>
                          Formulated contact for leads; maintained wholesome
                          business relations with significant clientele,
                          achieving exceptional performance parameters
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <div className="col-lg-2 col-md-12 col-sm-12 col-12 d-flex justify-content-lg-end justify-content-md-start justify-content-sm-start justify-content-start">
                  <i class="fa fa-long-arrow-right"></i>
                </div> */}
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <div className="lets-make-something">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="data_cntainer">
                  <h2>
                    <div
                      class="wrapper_2 d-grid one"
                      style={{ fontFamily: "Anton" }}
                    >
                      <div className="d-inline-flex">
                        <div class="heading_2">L</div>
                        <div class="heading_2">E</div>
                        <div class="heading_2">T</div>
                        <div class="heading_2">S</div>
                      </div>
                      <div className="d-inline-flex">
                        <div class="heading_2">M</div>
                        <div class="heading_2">A</div>
                        <div class="heading_2">K</div>
                        <div class="heading_2">E</div>
                      </div>
                      <div className="d-inline-flex">
                        <div class="heading_2">S</div>
                        <div class="heading_2">O</div>
                        <div class="heading_2">M</div>
                        <div class="heading_2">E</div>
                        <div class="heading_2">T</div>
                        <div class="heading_2">H</div>
                        <div class="heading_2">I</div>
                        <div class="heading_2">N</div>
                        <div class="heading_2">G</div>
                      </div>
                      <div className="d-inline-flex">
                        <div class="heading_2">G</div>
                        <div class="heading_2">R</div>
                        <div class="heading_2">E</div>
                        <div class="heading_2">A</div>
                        <div class="heading_2">T</div>
                      </div>
                      {/*  <span className="d-block">chaudhary</span> */}
                      {/* </h2> */}
                    </div>
                  </h2>
                </div>
                <div className="drop-email-section">
                  {/* <div class="container"> */}
                  {/* <div className="row"> */}
                  <div className="col-lg-12 mb-5">
                    <label style={{ fontFamily: "Roboto Condensed" }}>
                      drop me a line in direct
                    </label>
                    <p
                      className="mt-lg-3 mt-md-3"
                      style={{ fontFamily: "Anton" }}
                    >
                      hello@Amkamtech.com
                    </p>
                  </div>
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
              <div
                className="lets-make-image col-lg-4"
                style={{ backgroundImage: `url(${Model})` }}
              >
                {" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Sahilmishra;
