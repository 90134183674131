import React, { useState } from "react";
import "../components/navBar/style.css";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

const Hamburger = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="header_section_container">
      {/* <header className="header stickyHeader"> */}
      <div className="container">
        <div className="row justify-content-between align-items-center py-3">
          {/* <div className="heading-box d-flex align-items-center col-6">
              <div className="logo-container me-3"></div>
              <h3 className="header-heading">
                <HashLink to="/">Logo</HashLink>
              </h3>
            </div> */}
          <div className="user-icon-box col-6 d-flex justify-content-end">
            <Button
              variant="primary"
              onClick={handleShow}
              className="me-2 hemburger"
            >
              {/* <img src={Hemburger} alt="menu_icon" /> */}
            </Button>

            <Offcanvas
              show={show}
              onHide={() => navigate(-1)}
              className="side-offcanvas"
            >
              <div className="container">
                <Offcanvas.Header className="px-0" closeButton>
                  <Offcanvas.Title>
                    <div className="d-flex align-items-center">
                      <div className="logo-container me-3"></div>
                      <h3 className="header-heading">
                        <HashLink to="/">Logo</HashLink>{" "}
                      </h3>
                    </div>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvas-body p-0">
                  <div className="mt-5 ms-lg-5 ms-md-5 ms-sm-2 ms-2 ps-lg-5 ps-md-5 ps-sm-0 ps-0">
                    <ul>
                      <HashLink to="/menu">
                        <li className="menu">MENU</li>
                      </HashLink>
                      <HashLink to="/menu#about">
                        <li className="list-item">ABOUT US</li>
                      </HashLink>
                      <HashLink to="/menu#project">
                        <li className="list-item">PROJECTS</li>
                      </HashLink>
                      <li className="list-item">
                        <Dropdown drop="end">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            SERVICES
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <HashLink to="/ecommerce">
                              <Dropdown.Item
                                href="#/action-1"
                                className="dropdown_list"
                              >
                                Amkamtech ECOMMERCE
                              </Dropdown.Item>
                            </HashLink>
                            <HashLink to="/marketing">
                              <Dropdown.Item
                                href="#/action-2"
                                className="dropdown_list"
                              >
                                Amkamtech MARKETING
                              </Dropdown.Item>
                            </HashLink>
                            <HashLink to="/sahil-mishra">
                              <Dropdown.Item
                                href="#/action-3"
                                className="dropdown_list"
                              >
                                Sahil Mishra
                              </Dropdown.Item>
                            </HashLink>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <HashLink to="/menu#values">
                        <li className="list-item">VALUES</li>
                      </HashLink>
                      <HashLink to="/menu#process">
                        <li className="list-item">PROCESS</li>
                      </HashLink>
                      <HashLink to="/menu#team">
                        <li className="list-item">TEAM</li>
                      </HashLink>
                      <HashLink to="/menu#career">
                        <li className="list-item">CAREER</li>
                      </HashLink>
                    </ul>
                  </div>
                </Offcanvas.Body>
                <div className="social_icon row mt-4 align-items-center justify-content-between">
                  <div className="social-icon-container col-lg-6 col-md-6 col-sm-12 col-12 d-flex ">
                    <span className="social_icon_container">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </span>
                    <span className="social_icon_container">
                      <i class="fa fa-instagram" aria-hidden="true"></i>
                    </span>
                    <span className="social_icon_container">
                      <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="col-6 col-md-6 d-lg-flex d-md-flex d-sm-none d-none d-flex justify-content-end">
                    <b className="lets_grow">Let’s grow</b>
                  </div>
                </div>
              </div>
            </Offcanvas>
          </div>
        </div>
      </div>
      {/* </header> */}
    </div>
  );
};

export default Hamburger;
