import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/Home/index";
import MarketingPage from "../src/components/Marketing/index";
import HamburgerMenuPage from "./pages/HamburgerMenuPage";
import "./App.css";
import Hamburger from "./pages/Hamburger";
import EcommercePage from "./components/ecommercePage/index";
import Sahilmishra from "./components/sahilmishra";
import CareerPage from "./components/CareerPage";
import "./font/TabloidScuzzball.otf";
import WebDesignPage from "./pages/WebDesignPage";
import MobileAppsPage from "./pages/MobileAppsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import LegalPage from "./pages/LegalPage";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/marketing" element={<MarketingPage />} />
        <Route path="/webdesign" element={<WebDesignPage />} />
        <Route path="/mobileapps" element={<MobileAppsPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route path="/menu" element={<HamburgerMenuPage />} />
        <Route path="/ecommercepage" element={<EcommercePage />} />
        <Route path="/hamburger" element={<Hamburger />} />
        <Route path="/sahil-mishra" element={<Sahilmishra />} />
        <Route path="/career-page" element={<CareerPage />} />
      </Routes>
    </>
  );
};

export default App;
